/* eslint-disable */
import { AEMManifest } from './manifest-interface';
import { Stages } from './stages';

export const getAssetServer = (meta: AEMManifest): string => {
  if ((window as any).aemNamespace) {
    const NAME = `${meta.aemComponentConfig.productPrefix}-${meta.aemComponentConfig.tagNameWithoutPrefix}`;
    return `${(window as any).aemNamespace?.environmentVariables?.assetUri}/${NAME}/${NAME}-${
      meta.version
    }/`;
  }
  return '';
};

export const getAssetUrl = (stage: Stages): string => {
  if (stage === Stages.INT) {
    return 'https://int.assets.oneweb.mercedes-benz.com/plugin';
  }
  if (stage === Stages.PPROD) {
    return 'https://preprod.assets.oneweb.mercedes-benz.com/plugin';
  }
  if (stage === Stages.PROD) {
    return 'https://assets.oneweb.mercedes-benz.com/plugin';
  }
  return '';
}
