/* istanbul ignore file */
import store from '@/store';
import {
  ConsentDisplayStyle,
  ConsentMode,
  ConsentWarning,
  InitConfig,
  SupportedConsentLabels,
  WarningStyle,
} from '@dh-io-mmcs/consents-management-store/vuex';
import { aemStoreGetters } from '@commons-fe/aem-vuex-module';
import { B2XContext } from '@commons-fe/mmcs-models';
import { logger } from '@dh-io-mhb2b/fe-services';

export const getWarningProps = (): ConsentWarning => {
  const messages = ['mmc.cmw.infobox.revocation_modal.infotext1.b2b'];
  const anchor = undefined;

  return {
    header: 'mmc.step.terms_and_conditions.onebnb.warning.label',
    messages,
    anchor,
    style: WarningStyle.ERROR,
  };
};

export const buildInitConfig = (overrideParams: any): InitConfig => {
  const config = {
    allowCompanyReset: true,
    showConsentDate: true,
    oneConsentModeStyle: ConsentDisplayStyle.CHECKBOX,
    b2xContext: B2XContext.B2B,
    consentLabels: Object.values(SupportedConsentLabels),
    consentMode: ConsentMode.DEFAULT,
    ...(overrideParams?.root || {}),
    consentRules: {
      disableConsentsOnMandatoryConsentNotAgreed: true,
      showOnlyMandatory: false,
      ...(overrideParams?.consentRules || {}),
      labelRules: {
        [SupportedConsentLabels.ONE_BNB]: {
          isMandatory: true,
          showOnAgreed: true,
          consentWarning: getWarningProps(),
        },
        [SupportedConsentLabels.AVV]: {
          isMandatory: true,
          showOnAgreed: true,
          consentWarning: getWarningProps(),
        },
        [SupportedConsentLabels.PRODUCT_IMPROVEMENT]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.PRODUCT_IMPROVEMENT_COMPANY]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.PRODUCT_IMPROVEMENT_MAP]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.PRODUCT_IMPROVEMENT_MAP_COMPANY]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.PRODUCT_IMPROVEMENT_VOICE]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.PRODUCT_IMPROVEMENT_VOICE_COMPANY]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.MOBILITY_INFRASTRUCTURE_CONTRIBUTION]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.ADVERTISEMENT]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        [SupportedConsentLabels.ADDITIONAL_IT]: {
          isMandatory: false,
          showOnAgreed: true,
        },
        ...(overrideParams?.labelRules || {}),
      },
    },
    customTranslationKeys: [
      {
        label: SupportedConsentLabels.ONE_BNB,
        key: store.getters[aemStoreGetters.IS_B2B_CONTEXT]
          ? 'mmc.cmw.consents.privacy_policy.label.b2b'
          : 'mmc.step.terms_and_conditions.standalone.onebnb.label',
      },
      ...(overrideParams?.customTranslationKeys || []),
    ],
  };
  logger.log('buildInitConfig aemComponentRegistry', config);
  return config;
};
