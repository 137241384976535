/* eslint-disable @typescript-eslint/no-var-requires */
const packageMeta = require('./package.json');
const seamlessRc = require('./../../.seamlessrc.json');

const NAME = 'mh-cmw-dialog-wrapper';
const TENANT = seamlessRc.tenantId;
const pluginPath = `/${TENANT}-${NAME}/${TENANT}-${NAME}-${packageMeta.version}`;
const fileName = `${TENANT}-${NAME}`;

module.exports = {
  manifestVersion: '2.0',
  name: `${TENANT}-${NAME}`,
  version: packageMeta.version,
  config: {
    hasCss: false,
    files: {
      scripts: [
        {
          url: `${pluginPath}/${fileName}.min.js`,
          attrs: {
            type: 'module', // component
          },
        },
        {
          url: `${pluginPath}/${fileName}.es5.min.js`,
          attrs: {
            nomodule: '', //component old browsers remove its not supported
          },
        },
      ],
    },
    dependencies: {
      oneweb: '2.0',
      vue: '2.6.14',
    },
    isUserSessionRequired: false,
    integrationType: 'webcomponent',
    marketAvailability: [
      { country: 'CZ', language: 'cs' },
      { country: 'DK', language: 'da' },
      { country: 'AT', language: 'de' },
      { country: 'CH', language: 'de' },
      { country: 'DE', language: 'de' },
      { country: 'AU', language: 'en' },
      { country: 'CA', language: 'en' },
      { country: 'GB', language: 'en' },
      { country: 'IE', language: 'en' },
      { country: 'NZ', language: 'en' },
      { country: 'US', language: 'en' },
      { country: 'ES', language: 'es' },
      { country: 'FI', language: 'fi' },
      { country: 'BE', language: 'fr' },
      { country: 'CA', language: 'fr' },
      { country: 'CH', language: 'fr' },
      { country: 'FR', language: 'fr' },
      { country: 'HU', language: 'hu' },
      { country: 'CH', language: 'it' },
      { country: 'IT', language: 'it' },
      { country: 'KR', language: 'ko' },
      { country: 'BE', language: 'nl' },
      { country: 'NL', language: 'nl' },
      { country: 'NO', language: 'no' },
      { country: 'PL', language: 'pl' },
      { country: 'PT', language: 'pt' },
      { country: 'RO', language: 'ro' },
      { country: 'SK', language: 'sk' },
      { country: 'SE', language: 'sv' },
      { country: 'AQ', language: 'de' },
      { country: 'LU', language: 'de', market: 'LU' },
      { country: 'LU', language: 'fr', market: 'LU' },
    ],
  },
  aemComponentConfig: {
    title: 'CMW Dialog Wrapper',
    description: 'me Home | CMW Dialog Wrapper',
    componentGroup: 'Generic NG - Content',
    productPrefix: seamlessRc.tenantId,
    tagNameWithoutPrefix: 'mh-cmw-dialog-wrapper',
    dialog: [],
  },
};
