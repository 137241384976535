import { AxiosRequestConfig } from 'axios';

/**
 * Mandatory to use for MIC Backends. We change this header to X-MarketCountryCode once we make requests against MIC
 * Furthermore we use MMC-Market for our logging.
 */
export function getMmcMarketInterceptor(market: string): (config: AxiosRequestConfig<any>) => AxiosRequestConfig<any> {
  return (config: AxiosRequestConfig<any>) => {
    console.warn(
      'You are using an deprecated getMmcMarketInterceptor method and MMC-Market header parameter that will be replaced soon in favor to activeProfileInterceptor and market header parameter, source: https://git.daimler.com/dh-io-mhb2b/b2xpal/blob/develop/packages/api-interceptors/src/req/active-profile.ts',
    );
    const headers = {
      ...config.headers,
      'MMC-Market': market,
    };

    return {
      ...config,
      headers,
    };
  };
}
