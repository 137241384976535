import { initializeStore, SeamlessCoreStore } from '@seamless/store';
import { connection, connectionName } from './owc-translation-manager-connection';
import { OwcTranslationDispatchers, OwcTranslationManagerState, TranslationProject } from './types';
import { connectionName as connectionEnv, ConnectionAemEnvironmentState } from '@owc/connection-aem-environment';

/**
 * add a connection to the seamless store
 *
 *
 *     import { addTranslationManagerConnection, connectionName } from '@owc/owc-translation-manager-connection';
 *     import { initializeStore } from '@seamless/store';
 *     // create connection
 *     addTranslationManagerConnection().then((connection) => )...
 * @export
 * @function addTranslationManagerConnection
 */
export const addTranslationManagerConnection = async (): Promise<void> => {
  const store = initializeStore();
  return store.addConnection(connection);
};

/**
 * fetch the locale from the current AEM page
 *
 * Uses @owc/connection-aem-environment to fetch the page language and country
 *
 * @export
 * @function getLocale
 * @return Promise<string>
 */
export async function getLocale(): Promise<string> {
  const store: SeamlessCoreStore = initializeStore();
  return new Promise((resolve) => {
    store.subscribe(connectionEnv, (state: ConnectionAemEnvironmentState) => {
      resolve(`${state.language}-${state.country}`);
    });
  });
}

/**
 * the dispatchers from the addMetaTagsReducerConnection
 *
 *      javascript
 *      import { getMetaTagsDispatchers, connectionName } from '@owc/owc-meta-tags-manager-connection';
 *
 *      // create connection
 *      getTranslationDispatchers().then((dispatchers) => dispatchers.getTranslation() ...
 *
 * @export
 * @function getTranslationDispatchers
 * @return Promise<getTranslationDispatchers>
 */
export const getTranslationDispatchers = async (): Promise<OwcTranslationDispatchers> => {
  const store = initializeStore();
  return store.getConnectionDispatchers<OwcTranslationDispatchers>(connectionName).then((dispatchers) => {
    return dispatchers;
  });
};

/**
 * getTranslation will provide you with the requested translation.
 * It will ensure that that specific translation was not yet fetch and if not it will fetch it for you.
 * It will also store it for the case where different components request the same translations.
 *
 * @export
 * @param {string} projectID - Phrase project identifier
 * @param {string} locale - locale from the desired translation
 * @param {string} fileFormat - desired fileFormat for the downloaded translations object
 * @function getTranslationDispatchers
 * @return Promise<Record<string, string>>
 */
export async function getTranslation(
  projectID: string,
  locale: string,
  fileFormat = 'i18next',
): Promise<Record<string, string>> {
  const store: SeamlessCoreStore = initializeStore();
  return new Promise((resolve, reject) => {
    store.subscribe(connectionName, async (state: OwcTranslationManagerState) => {
      const projectLocale: TranslationProject = state[projectID];
      if (projectLocale && projectLocale[locale]) {
        resolve(projectLocale[locale]);
      } else {
        try {
          const newTranslation = await fetchTranslation(locale, projectID, fileFormat);
          await pushTranslation(projectID, locale, newTranslation);
          resolve(newTranslation);
        } catch {
          reject(`Error loading locale: ${locale}`);
        }
      }
    });
  });
}

/**
 * pushTranslation ensures that newly fetched translations are stored in our Store connection
 * to guarantee that one translation is not fetched multiple times.
 *
 * @param {string} projectID - Phrase project identifier
 * @param {string} locale - locale from the desired translation
 * @param {Record<string, string>} locale - locale from the desired translation
 * @function pushTranslation
 * @return void
 */
function pushTranslation(projectID: string, locale: string, translation: Record<string, string>): void {
  const translationMessage: TranslationProject = {
    [locale]: translation,
  };

  getTranslationDispatchers().then((dispatchers) => {
    dispatchers.pushTranslationMessage(projectID, translationMessage);
  });
}

/**
 * fetchTranslation will fetch the requested translations from Phrase using the default API call.
 * Example: https://i18n.oneweb.mercedes-benz.com/projects/myProject/locales/pt_PT/download?file_format=i18next
 *
 * @param {string} projectID - Phrase project identifier
 * @param {string} locale - locale from the desired translation
 * @param {string} fileFormat - desired fileFormat for the downloaded translations object
 * @function pushTranslation
 * @return Promise<Record<string, string>>
 */
async function fetchTranslation(locale: string, projectID: string, fileFormat: string): Promise<Record<string, any>> {
  return fetch(
    `https://i18n.oneweb.mercedes-benz.com/projects/${projectID}/locales/${locale}/download?file_format=${fileFormat}`,
  )
    .then((translationRequestResponse) => translationRequestResponse.json())
    .then((translationMessages) => {
      return translationMessages;
    });
}
