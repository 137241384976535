import axios from 'axios';
import { ProfilesApi, CompaniesApi, Configuration as ProfilesConfiguration } from '@dh-io-mhb2b/contracts-profiles';
import {
  PermissionsApi,
  ActiveUserProfileApi,
  Configuration as PermissionsConfiguration,
} from '@dh-io-mhb2b/b2x-permissions';
import {
  TrackingIdInterceptor,
  GetCorsCredentialsInterceptor,
  GetApplicationNameInterceptor,
  GetBearerTokenInterceptor,
  ContentTypeInterceptor,
} from '@dh-io-mhb2b/api-interceptors';
import { activeProfileInterceptor as ActiveProfileInterceptor } from './interceptors/active-profile';
import { RelativePathOptions } from './environments';
import { configHandler, Config } from './api-config';

export interface ApiInstances {
  profileApi: ProfilesApi;
  companyProfileApi: CompaniesApi;
  permissionsApi: PermissionsApi;
  activeUserProfileApi: ActiveUserProfileApi;
}

export async function createAxiosInstance(config: Config, applicationName?: string): Promise<any> {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(ActiveProfileInterceptor);
  axiosInstance.interceptors.request.use(ContentTypeInterceptor);
  axiosInstance.interceptors.request.use(TrackingIdInterceptor);
  axiosInstance.interceptors.request.use(GetApplicationNameInterceptor(applicationName || ''));
  axiosInstance.interceptors.request.use(GetBearerTokenInterceptor());
  axiosInstance.interceptors.request.use(GetCorsCredentialsInterceptor(config as any));

  return axiosInstance;
}

export async function instantiateApi(
  relativePath?: RelativePathOptions,
  applicationName?: string,
): Promise<ApiInstances> {
  const config: Config = await configHandler.getConfig(relativePath);
  const axiosInstance = await createAxiosInstance(config, applicationName);

  return {
    profileApi: new ProfilesApi(
      new ProfilesConfiguration(),
      `${config.apiBasePath}/mhb2b-profile-overview/mhb2b`,
      axiosInstance,
    ),
    companyProfileApi: new CompaniesApi(
      new ProfilesConfiguration(),
      `${config.apiBasePath}/mhb2b-company-profile/mhb2b`,
      axiosInstance,
    ),
    permissionsApi: new PermissionsApi(
      new PermissionsConfiguration(),
      `${config.apiBasePath}/mhb2b-permissions/mhb2b`,
      axiosInstance,
    ),
    activeUserProfileApi: new ActiveUserProfileApi(
      new PermissionsConfiguration(),
      `${config.apiBasePath}/mhb2b-permissions/mhb2b`,
      axiosInstance,
    ),
  };
}

export const apiHandler = {
  async getInstances(relativePath?: RelativePathOptions, applicationName = 'b2xpal'): Promise<ApiInstances> {
    return await instantiateApi(relativePath, applicationName);
  },
};
