import { initializeStore } from '@seamless/store';
import { AUTHENTICATION_CONNECTION, isLoggedIn } from '@b2x/authentication-library';

/**
 * help function to check if user is logged in ( = jwe in B2XCORE_USER_LOGIN Store is available)
 * some Apps make Backend Calls after beeing redirected from login page and in some cases
 * these calls happen before the B2XCore store wrote the jwe (i.e TOUC).
 * You should use this function in the initialisation process of your app to handle
 * your data when the user is logged in and do other stuff while waiting (i.e. show a skeleton )
 * @returns a Promise which only resolves when the user is logged in
 */
export const waitForUserLogin = async (): Promise<boolean> => {
  const store = initializeStore();
  return new Promise((res) => {
    store.subscribe(AUTHENTICATION_CONNECTION, (state) => {
      if (isLoggedIn(state)) {
        res(true);
      }
    });
  });
};
