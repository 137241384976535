export enum IdentityProvider {
  BIAS = 'BIAS',
  CIAS = 'CIAS',
}

export enum ActiveLoginTypes {
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum Stages {
  LOCAL = 'local',
  DEVELOP = 'dev',
  TEST = 'test',
  INT = 'int',
  PPROD = 'pprod',
  PROD = 'prod',
}

export enum TENANT {
  ONEWEB = 'oneweb',
  OFLOGIN = 'OFLOGIN',
  CIAS = 'cias',
  MARKETPLACE_CORE = 'mrkplc-core',
}

export enum AlertType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  HIGHLIGHT = 'HIGHLIGHT',
}
