const countryMappings: any = {
  XA: 'AE-AZ',
  XD: 'AE-DU',
};

export const countryMapping = {
  toCountry(countryCode: string): string {
    const mappedCode = countryMappings[countryCode];

    if (mappedCode) {
      return mappedCode;
    }

    return countryCode;
  },
};
