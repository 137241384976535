import { CiasLoginParams, CiasLogoutParams, IdentityProvider, Logger, TENANT } from '@b2x/common';
import { initializeStore, SeamlessConnection } from '@seamless/store';
import { Reducer } from 'redux';

import { AUTHENTICATION_CONNECTION, AUTHENTICATION_CONNECTION_CIAS } from '../common/constants';
import { AsyncThunk, AuthenticationDispatchers, AuthenticationParams } from '../types/auth';

type AuthStateCias = Record<string, undefined>;

export type AuthDispatchersCias = {
  initAuthentication: () => void;
  login: (options?: CiasLoginParams) => void;
  logout: (options?: CiasLogoutParams) => void;
  setInitParams: () => void;
};

export class AuthConnectionCias extends SeamlessConnection<AuthDispatchersCias, AuthStateCias> {
  protected logger = new Logger(AUTHENTICATION_CONNECTION_CIAS);

  seamlessStore = initializeStore();

  constructor(connectionName = AUTHENTICATION_CONNECTION_CIAS) {
    super(connectionName);
  }

  get initialState(): AuthStateCias {
    return {};
  }

  getReducer(): Reducer {
    return (state: AuthStateCias): AuthStateCias => state;
  }

  getPublicDispatchers(): AuthDispatchersCias {
    return {
      initAuthentication: () => ({ type: 'EMPTY' }),
      login: this.loginAction.bind(this),
      logout: this.logoutAction.bind(this),
      setInitParams: () => ({ type: 'EMPTY' }),
    };
  }

  loginAction(options?: CiasLoginParams): AsyncThunk {
    return async () => {
      const dispatchers = await this.seamlessStore.getConnectionDispatchers<AuthenticationDispatchers>(
        AUTHENTICATION_CONNECTION,
      );

      const authParams: AuthenticationParams = {
        identityProvider: IdentityProvider.CIAS,
        tenantId: location.hostname.includes('localhost') ? TENANT.CIAS : TENANT.ONEWEB,
        ...options,
      };

      dispatchers.login(authParams);
    };
  }

  logoutAction(options?: CiasLogoutParams): AsyncThunk {
    return async () => {
      const dispatchers = await this.seamlessStore.getConnectionDispatchers<AuthenticationDispatchers>(
        AUTHENTICATION_CONNECTION,
      );

      dispatchers.logout(options);
    };
  }
}
