import { AxiosRequestConfig } from 'axios';

export const ActiveProfileSessionStorageKey = 'mhb2b_active_profile';

/**
 * MHB2B-ActiveProfile header is required for the BFF in order to return user permissions based on the user
 * role (e.g. driver or admin) within a company. Active profile is pulled from the session storage.
 */

// TODO deprecated -- remove after migration to CMW (MMWEB-8875)
export function activeProfileInterceptor(config: AxiosRequestConfig<any>): AxiosRequestConfig<any> {
  const activeProfile = window.sessionStorage.getItem(ActiveProfileSessionStorageKey);
  if (activeProfile) {
    try {
      const { company, roles } = JSON.parse(activeProfile);
      const activeProfileHeaderContent = {
        company: {
          domainRef: {
            meId: company?.domainRef?.meId,
          },
        },
        roles: (roles || [])
          .map((r: any) => (r.domainRef ? { domainRef: r.domainRef } : undefined))
          .filter((r: any) => !!r && !!r.domainRef),
      };
      const activeProfileHeader = {
        'MHB2B-ActiveProfile': JSON.stringify(activeProfileHeaderContent),
      };
      const headers = {
        ...config.headers,
        ...activeProfileHeader,
      };
      return {
        ...config,
        headers,
      };
    } catch (e) {
      console.error(e);
    }
  }

  return config;
}
