import Vue from 'vue';
import wrap from '@vue/web-component-wrapper';
import { addTranslationManagerConnection } from '@owc/connection-aem-translations';
import { initializeAuthentication, IdentityProvider } from '@b2x/authentication-library';
import { initializeStore } from '@seamless/store';
import { B2XActiveUserConnection, activateUserProfile } from '@dh-io-mhb2b/active-profile';

import { DirectiveOptions } from 'vue/types/options';
import { I18nPlugin } from './i18n';
import { getAssetServer } from './asset-server';
import TrackingDirective from './tracking-directive';
import { Stages } from './stages';
import seamlessRc from '../../../.seamlessrc.json';

export interface DevValues {
  stage?: Stages;
  country?: string;
  language?: string;
  runMode?: string;
}

const defaultDevValues: DevValues = {
  stage: Stages.INT,
  country: 'GB',
  language: 'en',
  runMode: 'PUBLISH',
};

export interface DevApplicationConfig {
  preventActiveUserConnection?: boolean;
  devValues?: DevValues;
}

export interface ApplicationConfig {
  oneWebFile?: any;
}

// This should only be called on DEV environment, it basically mocks and starts what is needed for a local development (in the main.ts file)
export const startDevApplication = async (
  component: any,
  componentName: string,
  config: DevApplicationConfig = {},
): Promise<void> => {
  const devValues: DevValues = {
    ...defaultDevValues,
    ...config.devValues,
  };

  // Authentication
  await initializeAuthentication({
    identityProvider: IdentityProvider.CIAS,
    tenantId: seamlessRc.tenantId,
    country: devValues.country,
    language: devValues.language,
    stage: devValues.stage as Stages,
    loginOptions: {
      redirectUrl: location.origin,
    },
    logoutOptions: {
      targetUrl: location.origin,
    },
  });

  // B2X Active Profile
  if (!config.preventActiveUserConnection) {
    await initializeStore().addConnection(new B2XActiveUserConnection());
    await activateUserProfile({});
  }

  // Translations
  await addTranslationManagerConnection();

  // start component
  const CustomElement = wrap(Vue, component) as unknown as CustomElementConstructor;
  window.customElements.define(componentName, CustomElement);
};

// This should always be called, be it on dev or any other environment (in the App.ts file)
export const startApplication = async (config: ApplicationConfig = {}): Promise<void> => {
  // Base configs, directives and libraries
  Vue.config.productionTip = false;
  Vue.config.ignoredElements.push(/wb-w*/, /mh-*/, /mhb2b-*/, /mmcs-*/, /mmv-*/);
  Vue.directive('track-click', TrackingDirective as DirectiveOptions);
  Vue.use(I18nPlugin);

  // TODO check if we can add the web component loader, the vue component library (and maybe other libraries as well)
  // TODO maybe we can already start translations directly here

  // Webpack public path for asset server
  if (config.oneWebFile && getAssetServer) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const assetUrl = getAssetServer(config.oneWebFile);
    if (assetUrl) {
      __webpack_public_path__ = assetUrl;
    }
  }
};
