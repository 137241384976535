import { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

/**
 * We use x-trackingid for our logging. Each request has its own trackingId
 * We send this x-trackingid to the MIC-Backends as well
 */
export function trackingIdInterceptor(config: AxiosRequestConfig<any>): AxiosRequestConfig<any> {
  const headers = {
    ...config.headers,
    'X-TrackingId': uuidv4() || '',
  };

  return {
    ...config,
    headers,
  };
}
