import axios from 'axios';
import { AemEnvironmentHandler } from '@dh-io-mhb2b/aem-services';
import { GetMmcMarketInterceptor, GetMmcLocaleInterceptor } from '@dh-io-mhb2b/http-interceptors';

import {
  GetApplicationNameInterceptor,
  GetBearerTokenInterceptor,
  ContentTypeInterceptor,
  TrackingIdInterceptor,
} from '@dh-io-mhb2b/api-interceptors';

import {
  Configuration,
  FinanceConsentsApi,
  CompanyId,
  FinanceConsent,
  FinanceConsentCUDReq,
  FilterOperator,
  ConsentState,
} from '@dh-io-mhb2b/contracts-dmo-finance';
import { ActiveProfileInterceptor, getReadyActiveProfile } from '@dh-io-mhb2b/active-profile';
import { getApiBaseUrl } from './environments';

class DataService {
  private _apiInstance!: any;

  private createFspRequestPayload() {
    const getFinanceConsentFilter = (expectedState: string) => ({
      filter: {
        operator: FilterOperator.Eq,
        operands: [
          {
            expression: {
              propertyPath: 'state',
            },
          },
          {
            literal: {
              value: expectedState,
            },
          },
        ],
      },
    });
    const fspRequest = {
      filterDescriptor: {
        entityType: 'FinanceConsent',
        operation: {
          bool: {
            operator: 'OR',
            operands: [getFinanceConsentFilter(ConsentState.Accepted), getFinanceConsentFilter(ConsentState.Revoked)],
          },
        },
      },
      includeDescriptor: {
        includedPropertyPaths: [
          'state',
          'domainRef.definition.version',
          'domainRef.definition.id',
          'definition.document.data',
          'definition.document.title',
        ],
      },
    };
    return fspRequest;
  }

  async getCompanyDomainRef(): Promise<CompanyId> {
    const activeProfile = await getReadyActiveProfile();

    if (!activeProfile) {
      throw new Error('active profile is missing');
    }

    return activeProfile?.company?.domainRef as CompanyId;
  }

  async getApiInstance(): Promise<any> {
    /* istanbul ignore next */
    if (!this._apiInstance) {
      const stage = await AemEnvironmentHandler.getStage();
      if (!stage) {
        // can't proceed
        throw new Error('No stage defined in aemNamespace.environmentVariables.stage');
      }
      const { market, locale } = await AemEnvironmentHandler.getMarketAndLocale();
      const { serverApiUrl } = getApiBaseUrl(market, stage);
      const config = {
        apiBasePath: serverApiUrl,
        id: stage,
      };

      const axiosInstance = axios.create();
      axiosInstance.interceptors.request.use(GetMmcMarketInterceptor(market) as any);
      axiosInstance.interceptors.request.use(GetMmcLocaleInterceptor(locale) as any);
      axiosInstance.interceptors.request.use(TrackingIdInterceptor as any);
      axiosInstance.interceptors.request.use(ContentTypeInterceptor as any);
      axiosInstance.interceptors.request.use(GetApplicationNameInterceptor('mhb2b') as any);
      axiosInstance.interceptors.request.use(GetBearerTokenInterceptor() as any);
      axiosInstance.interceptors.request.use(ActiveProfileInterceptor as any);

      this._apiInstance = new FinanceConsentsApi(
        new Configuration(),
        `${config.apiBasePath}/dmo-consent/mhb2b`,
        axiosInstance,
      );
    }

    return this._apiInstance;
  }

  async getConsent(): Promise<FinanceConsent[]> {
    const apiInstance = await this.getApiInstance();
    const fspRequest = this.createFspRequestPayload();
    const response = await apiInstance.getFinanceConsents({
      requests: [{ fspRequest }],
    });
    return response.data?.responses[0]?.items || [];
  }

  async createConsent(payload: FinanceConsentCUDReq): Promise<FinanceConsent[]> {
    const apiInstance = await this.getApiInstance();
    const response = await apiInstance.modifyFinanceConsents({
      requests: [{ payload }],
    });
    return response.data?.responses[0]?.items || [];
  }

  async updateConsent(payload: FinanceConsentCUDReq): Promise<FinanceConsent[]> {
    const apiInstance = await this.getApiInstance();
    const response = await apiInstance.modifyFinanceConsents(payload);
    return response.data?.updated || [];
  }
}

export const DmoDataService = new DataService();
