const countryMappings: any = {
  XA: 'AE-AZ',
  XD: 'AE-DU',
};

export const CountryMapping = {
  mapCountry(countryCode: string): string {
    return countryMappings[countryCode] ?? countryCode;
  },
};
