import { ActiveUserProfile, Fleet, Vehicle } from '@dh-io-mhb2b/api-model';

import { apiHandler } from '../../api/api-service';
import { B2XActiveProfileState } from '../state';

declare const VERSION: string;

export const saveStateToBackend = async (state: B2XActiveProfileState): Promise<void> => {
  const stateToPersist: ActiveUserProfile = {};
  if (state.user?.domainRef) {
    stateToPersist.user = {
      domainRef: state.user.domainRef,
    };
  }
  if (state.company?.domainRef) {
    stateToPersist.company = {
      domainRef: state.company.domainRef,
    };
  } else if (state.company) {
    stateToPersist.company = {};
  }
  if (state.roles?.length) {
    stateToPersist.roles = state.roles;
  }
  if (state.fleets?.length) {
    stateToPersist.fleets = state.fleets.map((fleet) => ({ domainRef: fleet.domainRef } as Fleet));
  }
  if (state.vehicles?.length) {
    stateToPersist.vehicles = state.vehicles.map((vehicle) => ({ domainRef: vehicle.domainRef } as Vehicle));
  }
  if (state.market) {
    stateToPersist.market = state.market;
  }
  if (state.locale) {
    stateToPersist.locale = state.locale;
  }

  const { activeUserProfileApi } = await apiHandler.getInstances('MHB2B');
  await activeUserProfileApi.saveCurrentActiveUserProfile({ activeUserProfile: stateToPersist });
};
