import { ActiveLoginTypes, AuthenticationState, AuthState, IdentityProvider, Logger } from '@b2x/common';
import { SeamlessConnection } from '@seamless/store';
import { AnyAction, Reducer } from 'redux';

import { AUTHENTICATION_CONNECTION_LEGACY, initialStateLegacy } from '../common/constants';
import { isLoggedIn } from '../common/selectors';
// import { dispatchTracking } from '../services/tracking';

export type AuthDispatchersLegacy = {
  replaceState: (state: AuthenticationState) => AnyAction | void;
};

const REPLACE_STATE = 'REPLACE_STATE';

export class AuthConnectionLegacy extends SeamlessConnection<AuthDispatchersLegacy, AuthState> {
  protected logger = new Logger(AUTHENTICATION_CONNECTION_LEGACY);

  constructor(connectionName = AUTHENTICATION_CONNECTION_LEGACY) {
    super(connectionName);
  }

  get initialState(): AuthState {
    return { ...initialStateLegacy };
  }

  getReducer(): Reducer {
    return (state: AuthState, action: AnyAction): AuthState => {
      if (action.type === this.getActionType(REPLACE_STATE)) {
        state = action.payload;
      }

      return state;
    };
  }

  getPublicDispatchers(): AuthDispatchersLegacy {
    return {
      replaceState: this.replaceStateAction.bind(this),
    };
  }

  public onStateChange(state: AuthState): Promise<void> {
    this.logger.log('LEGACY state has been changed', state);

    return super.onStateChange(state);
  }

  replaceStateAction(state: AuthenticationState): AnyAction | void {
    // dispatchTracking({
    //   category: 'feature',
    //   type: 'LL',
    //   label: 'public:connection',
    //   event: 'action',
    //   action: 'replace',
    // });

    const legacyState: AuthState = {
      auth: {
        jwe: state.jwe,
        jweIssuer: state.identityProvider,
        activeLoginType:
          state.identityProvider === IdentityProvider.CIAS ? ActiveLoginTypes.CUSTOMER : ActiveLoginTypes.EMPLOYEE,
        claims: state.claims,
        isLoggedIn: isLoggedIn(state),
      },
      userProfile: state.profile,
      alert: state.alert,
    };

    return this.getAction(REPLACE_STATE, legacyState);
  }
}
