import { CMStatus, CMStoreData, InitConfig, ValidationResult } from '../shared/model';

export const CMSConnectionName = 'MMCS_CM_STORE';

export type CMSeamlessStoreState = {
  [namespace: string]: CMStoreData;
};

export type CMStoreDispatchers = {
  updateConfig: (state: InitConfig, namespace: string) => CMStoreAction;
  updateStatus: (status: CMStatus, namespace: string) => CMStoreAction;
  updateTrackingId: (trackingId: string, namespace: string) => CMStoreAction;
  updateOldDocumentVersionAgreed: (status: boolean, namespace: string) => CMStoreAction;
  updateConsentCount: (consentCount: number, namespace: string) => CMStoreAction;
  updateValidationResult: (validationResult: ValidationResult, namespace: string) => CMStoreAction;
};

export const ActionTypes = {
  UPDATE_CONFIG: `${CMSConnectionName}/UPDATE_CONFIG`,
  UPDATE_STATUS: `${CMSConnectionName}/UPDATE_STATUS`,
  UPDATE_TRACKING_ID: `${CMSConnectionName}/UPDATE_TRACKING_ID`,
  UPDATE_OLD_DOCUMENT_VERSION_AGREED: `${CMSConnectionName}/UPDATE_OLD_DOCUMENT_VERSION_AGREED`,
  UPDATE_CONSENT_COUNT: `${CMSConnectionName}/UPDATE_CONSENT_COUNT`,
  UPDATE_VALIDATION_RESULT: `${CMSConnectionName}/UPDATE_VALIDATION_RESULT`,
};

export type CMStoreAction =
  | { type: typeof ActionTypes.UPDATE_CONFIG; payload?: { data: InitConfig; namespace: string } }
  | { type: typeof ActionTypes.UPDATE_STATUS; payload?: { data: CMStatus; namespace: string } }
  | { type: typeof ActionTypes.UPDATE_TRACKING_ID; payload?: { data: string; namespace: string } }
  | { type: typeof ActionTypes.UPDATE_OLD_DOCUMENT_VERSION_AGREED; payload?: { data: boolean; namespace: string } }
  | { type: typeof ActionTypes.UPDATE_CONSENT_COUNT; payload?: { data: number; namespace: string } }
  | { type: typeof ActionTypes.UPDATE_VALIDATION_RESULT; payload?: { data: ValidationResult; namespace: string } };
