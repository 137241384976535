import { FSPRequest, BinaryFilterOperator } from '@dh-io-mhb2b/contracts-profiles';

/* istanbul ignore next */
export class FspRequestBuilder {
  private request: FSPRequest = {
    filterDescriptor: {
      entityType: '',
      operation: {},
    },
    includeDescriptor: {
      entityInstancePermissions: true,
    },
  };

  withEntityType(entityType: string): FspRequestBuilder {
    this.request.filterDescriptor.entityType = entityType;
    return this;
  }

  withBinaryFilterEq(propertyPath: string, valueType: string, value: any): FspRequestBuilder {
    this.request.filterDescriptor.operation = {
      binaryFilter: {
        operator: BinaryFilterOperator.Eq,
        leftOperand: {
          expression: {
            propertyPath: propertyPath,
          },
        },
        rightOperand: {
          literal: {
            value: value,
            valueType: valueType,
          },
        },
      },
    };
    return this;
  }

  withIncludeDescriptor(includeDescriptor: any): FspRequestBuilder {
    this.request.includeDescriptor = includeDescriptor;
    return this;
  }

  getInstance(): FSPRequest {
    return this.request;
  }
}
