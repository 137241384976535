import { AuthenticationState, AuthState, IdentityProvider } from '@b2x/common';

import { AuthorityServiceConfig, Conditions, ServiceConfig } from '../types/auth';

// New connection names
export const AUTHENTICATION_CONNECTION = 'B2XCORE_AUTHENTICATION';
export const AUTH_PERSISTED_CONNECTION = `swsp:${AUTHENTICATION_CONNECTION}`;
// Legacy names
export const AUTHENTICATION_CONNECTION_LEGACY = 'B2XCORE_USER_LOGIN';
export const AUTHENTICATION_CONNECTION_CIAS = `${AUTHENTICATION_CONNECTION_LEGACY}_CIAS`;
export const AUTHENTICATION_CONNECTION_BIAS = `${AUTHENTICATION_CONNECTION_LEGACY}_BIAS`;

export const COOKIE_NAME = 'B2XCORE_SESSION';

export enum CONDITION_TYPE {
  ANONYMOUS = 'ANONYMOUS',
  AUTHENTICATING = 'AUTHENTICATING',
  ERROR = 'ERROR',
  LEGACY = 'LEGACY',
  AUTHENTICATED = 'AUTHENTICATED',
  SSO = 'SSO',
}

export enum QUERY_PARAMS {
  TOKEN = 'token',
  OTT = 'ott',
  ERROR = 'error',
  ERROR_ID = 'errorId',
  STATE = 'state',
  ERROR_CODE = 'error_code',
  ERROR_DESCRIPTION = 'error_description',
  EXCEPTION_ID = 'exception_id',
}

export const initialState: AuthenticationState = {
  jwe: null,
  claims: null,
  identityProvider: IdentityProvider.CIAS,
  isPending: false,
  profile: null,
  alert: null,
};

export const initialStateLegacy: AuthState = {
  auth: {
    jwe: '',
    jweIssuer: '',
    activeLoginType: '',
    claims: null,
    isLoggedIn: null,
  },
  userProfile: null,
  alert: null,
};

export const initialCondition: Conditions = {
  type: CONDITION_TYPE.ANONYMOUS,
  token: null,
  owcAemJwe: null,
  state: {} as AuthenticationState,
  error: {
    error: null,
    errorId: null,
    error_code: null,
    error_description: null,
    exception_id: null,
    state: null,
  },
  hasError: false,
  isPrivatePage: false,
};

const CIAS_HOST_TEST = 'https://test.api.oneweb.mercedes-benz.com/cias/v1';
const CIAS_HOST_INT = 'https://int.api.oneweb.mercedes-benz.com/cias/v1';
const CIAS_HOST_PROD = 'https://api.oneweb.mercedes-benz.com/cias/v1';

export const ciasConfig: AuthorityServiceConfig = {
  appName: 'b2xlc',
  tenantId: 'cias', // set to oneweb at runtime when in oneweb
  envs: {
    local: CIAS_HOST_TEST,
    dev: CIAS_HOST_TEST,
    test: CIAS_HOST_TEST,
    int: CIAS_HOST_INT,
    pprod: CIAS_HOST_INT,
    prod: CIAS_HOST_PROD,
  },
};

const BIAS_HOST_TEST = 'https://test.api.oneweb.mercedes-benz.com/bias-oidc/v1';
const BIAS_HOST_INT = 'https://int.api.oneweb.mercedes-benz.com/bias-oidc/v1';
const BIAS_HOST_PROD = 'https://api.oneweb.mercedes-benz.com/bias-oidc/v1';

export const biasConfig: ServiceConfig = {
  tenantId: 'OFLOGIN', // set to oneweb at runtime when in oneweb
  envs: {
    local: BIAS_HOST_TEST,
    dev: BIAS_HOST_TEST,
    test: BIAS_HOST_TEST,
    int: BIAS_HOST_INT,
    pprod: BIAS_HOST_INT,
    prod: BIAS_HOST_PROD,
  },
};

const B2XVS_HOST_DEV = 'https://dev.api.oneweb.mercedes-benz.com/b2xvs/v1';
const B2XVS_HOST_TEST = 'https://test.api.oneweb.mercedes-benz.com/b2xvs/v1';
const B2XVS_HOST_INT = 'https://int.api.oneweb.mercedes-benz.com/b2xvs/v1';
const B2XVS_HOST_PROD = 'https://api.oneweb.mercedes-benz.com/b2xvs/v1';

export const vsConfig: ServiceConfig = {
  envs: {
    local: B2XVS_HOST_TEST,
    dev: B2XVS_HOST_DEV,
    test: B2XVS_HOST_TEST,
    int: B2XVS_HOST_INT,
    pprod: B2XVS_HOST_INT,
    prod: B2XVS_HOST_PROD,
  },
};

const B2XPS_HOST_DEV = 'https://dev.api.oneweb.mercedes-benz.com/b2xps/v1';
const B2XPS_HOST_TEST = 'https://test.api.oneweb.mercedes-benz.com/b2xps/v1';
const B2XPS_HOST_INT = 'https://int.api.oneweb.mercedes-benz.com/b2xps/v1';
const B2XPS_HOST_PROD = 'https://api.oneweb.mercedes-benz.com/b2xps/v1';

export const psConfig: ServiceConfig = {
  envs: {
    local: B2XPS_HOST_TEST,
    dev: B2XPS_HOST_DEV,
    test: B2XPS_HOST_TEST,
    int: B2XPS_HOST_INT,
    pprod: B2XPS_HOST_INT,
    prod: B2XPS_HOST_PROD,
  },
};
