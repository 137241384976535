import { apiGatewayConfig } from '../types/wko.interface';
import { Logger } from '../utils/logger';

export class WKOService {
  protected static logger = new Logger('WKO_SERVICE');

  // TODO: remove this method when oneweb adapter goes live
  static getApiGatewayConfig(): apiGatewayConfig | null {
    const gatewayConfig = sessionStorage.getItem('ow_aem_apiGatewayConfiguration');

    return gatewayConfig ? JSON.parse(gatewayConfig) : null;
  }

  // TODO: remove this method when oneweb adapter goes live
  static getAemJwe(): string {
    return sessionStorage.getItem('ow_aem_jwe') || '';
  }

  // TODO: remove this method when oneweb adapter goes live
  static getLoginUrl(): string {
    const loginUrl = sessionStorage.getItem('ow_aem_ciamLoginUrl');

    if (loginUrl === null) {
      const msg = 'CIAM login url could not be found in session store';
      this.logger.error(msg);
      throw Error(msg);
    }

    return loginUrl;
  }

  // TODO: remove this method when oneweb adapter goes live
  static getLogoutUrl(): string {
    const logoutUrl = sessionStorage.getItem('ow_aem_ciamLogoutUrl');

    if (logoutUrl === null) {
      const msg = 'CIAM logout url could not be found in session store';
      this.logger.error(msg);
      throw Error(msg);
    }

    return logoutUrl;
  }

  static getPostLogoutUrl(): string | null {
    const postLogoutUrl = sessionStorage.getItem('ow_aem_postLogoutCallbackUrl');

    if (postLogoutUrl === null) {
      const msg = 'CIAM post logout url could not be found in session store';
      this.logger.warn(msg);
    }

    return postLogoutUrl;
  }

  static getPostLoginUrl(): string | null {
    const postLoginUrl = sessionStorage.getItem('ow_aem_postLoginCallbackUrl');

    if (postLoginUrl === null) {
      const msg = 'CIAM post logout url could not be found in session store';
      this.logger.warn(msg);
    }

    return postLoginUrl;
  }
  static getMissingData(): string[] {
    const { missingFields = [] } = (window as any).ow?.upmc?.getMissingDataConfig() || {};

    return missingFields;
  }
}
