enum Region {
  ECE = 'ECE',
  AMAP = 'AMAP',
  CN = 'CN',
}

export class RegionMapping {
  static countryRegionMap = new Map<Region, Array<string>>([
    [
      Region.ECE,
      [
        'AT',
        'BE',
        'BG',
        'HR',
        'CY',
        'CZ',
        'DK',
        'EE',
        'FI',
        'FR',
        'DE',
        'GR',
        'HU',
        'IN',
        'IE',
        'IT',
        'LV',
        'LI',
        'LT',
        'LU',
        'MT',
        'NL',
        'NO',
        'PL',
        'PT',
        'RO',
        'RU',
        'SK',
        'SI',
        'ZA',
        'ES',
        'SE',
        'CH',
        'TH',
        'TR',
        'GB',

        // Note: XA and XD provided by OneWeb will be mapped to AE-AZ
        // and AE-DU internally before calling the toRegion function.
        // The mapping is required for our backends to work. For the
        // region mapping we could just use XA and XD directly, but
        // using the AE values across the whole application is more
        // consistent and less confusing.
        'AE-AZ',
        'AE-DU',
      ],
    ],
    [Region.AMAP, ['AU', 'BR', 'CA', 'JP', 'MY', 'MX', 'NZ', 'KR', 'SG', 'TW', 'US']],
    [Region.CN, ['CN', 'HK', 'MO']],
  ]);

  static toRegion(country: string): string {
    let region = '';
    this.countryRegionMap.forEach((value, key) => {
      if (value.toString().includes(country)) {
        region = Region[key];
      }
    });
    if (region) {
      return region;
    } else {
      console.log('Country not supported!');
      throw new Error();
    }
  }
}
