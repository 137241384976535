import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { initializeLogger } from '@seamless/logger';
import { B2XActiveProfileState } from '../active-profile-store/state';
import { getAemEnvironmentPromise } from '../aem/aem-environment';

/**
 * This module extracts the backend service comunication
 * client taken from https://git.daimler.com/dh-io-cscs/cscs-user-context-manager/blob/7f6f784a4fdeb55b6bffba0659d9542de1573531/packages/user-context-manager/src/services/ucms/ucmsService.ts
 * in order to be able to set the user context in the backend side when a
 * new profile is selected.
 */

const logger = initializeLogger('UCMS-SERVICE');

interface ContextTokenResponse {
  token: string;
}

interface SetContextResponse extends ContextTokenResponse {
  expirationDate: string;
}

export interface CustomerGroup {
  name: string;
  label: string;
  default: boolean;
}

export interface ContextConfig {
  /**
   * Context label:
   * E.g.: { label: "Private customer" }
   */
  label: string;
  redirectUrl?: string;
  /**
   * Context id:
   * E.g.: { contextId: "B2C" }
   */
  contextId: ContextType;
  /**
   * Last selected company profile
   */
  lastSelectedCompanyProfileId?: string;
}

export interface UserContextManagerState {
  default: boolean;
  context: ContextType;
  customerGroup: CustomerGroupType;
  lastSelectedCompanyProfile?: string;
}

export enum ENVIRONMENTS {
  DEV = 'dev',
  TEST = 'test',
  INT = 'int',
  PPROD = 'pprod',
  PROD = 'prod',
}

export enum CONTEXT_TYPES {
  B2B = 'B2B',
  B2C = 'B2C',
}

export enum CUSTOMER_GROUPS {
  B2C_DEFAULT = 'B2C_DEFAULT',
  B2B_DEFAULT = 'B2B_DEFAULT',
}

export type ContextType = CONTEXT_TYPES.B2C | CONTEXT_TYPES.B2B;
export type CustomerGroupType = CUSTOMER_GROUPS.B2C_DEFAULT | CUSTOMER_GROUPS.B2B_DEFAULT;
export type EnvironmentType =
  | ENVIRONMENTS.DEV
  | ENVIRONMENTS.TEST
  | ENVIRONMENTS.INT
  | ENVIRONMENTS.PPROD
  | ENVIRONMENTS.PROD;

export class UserContext {
  label!: string;
  isDefault!: boolean;
  context!: ContextType;
  redirectUrl!: string | undefined;
  customerGroup!: CustomerGroupType;
  lastSelectedCompanyProfileId?: string;

  constructor({ label, redirectUrl, contextId, lastSelectedCompanyProfileId }: ContextConfig, isDefault: boolean) {
    this.label = label;
    this.isDefault = isDefault;
    this.redirectUrl = redirectUrl;
    this.customerGroup = contextId === CONTEXT_TYPES.B2C ? CUSTOMER_GROUPS.B2C_DEFAULT : CUSTOMER_GROUPS.B2B_DEFAULT;
    this.context = contextId === CONTEXT_TYPES.B2C ? CONTEXT_TYPES.B2C : CONTEXT_TYPES.B2B;
    this.lastSelectedCompanyProfileId = lastSelectedCompanyProfileId;
  }

  getState(): UserContextManagerState {
    return {
      default: this.isDefault,
      customerGroup: this.customerGroup,
      context: this.context,
      lastSelectedCompanyProfile: this.lastSelectedCompanyProfileId,
    };
  }
}

export class Http {
  private client!: AxiosInstance;

  constructor(public baseUrl = '') {
    this.client = axios.create({ baseURL: baseUrl });
  }

  async get<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.client.get(url, { ...options, withCredentials: true });
    return data;
  }

  async post<T, R>(url: string, data: T, options?: AxiosRequestConfig): Promise<R> {
    const { data: responseData } = await this.client.post(url, data, { ...options, withCredentials: true });
    return responseData;
  }
}

const getUrl = (suffix: string) => {
  const _suffix = suffix ? `${suffix}.` : '';
  return 'https://' + _suffix + 'api.oneweb.mercedes-benz.com/ucms/v1';
};

const apis = {
  // DEV
  [ENVIRONMENTS.DEV]: getUrl('test'),
  // TEST
  [ENVIRONMENTS.TEST]: getUrl('test'),
  // INT
  [ENVIRONMENTS.INT]: getUrl('int'),
  // PRPROD: Is usually mapped to INT
  [ENVIRONMENTS.PPROD]: getUrl('int'),
  // PROD
  [ENVIRONMENTS.PROD]: getUrl(''),
};

export function getBaseUrl(environment: EnvironmentType): string {
  return apis[environment] || apis[ENVIRONMENTS.PROD];
}

const contextTokenKey = 'ucms.contextToken';

export const getContextToken = (): string | null => {
  return localStorage.getItem(contextTokenKey);
};

export const setContextToken = (token: string): void => {
  localStorage.setItem(contextTokenKey, token);
};

export const getUrlParam = (name: string, url: string): string | null => {
  const uri = new URL(url);

  return uri.searchParams.get(name);
};

export const setUrlParam = (name: string, url: string, value = getContextToken()): string => {
  try {
    const uri = new URL(url);
    uri.searchParams.set(name, value as string);
    return uri.toString();
  } catch {
    return url;
  }
};

export const removeUrlParam = (name: string, url: string): string => {
  const uri = new URL(url);

  try {
    uri.searchParams.delete(name);
  } catch (_e) {
    // Nothing to do
  }

  return uri.toString();
};

export const getParamContextToken = (): string | null => {
  const context = getUrlParam('ct', window.location.href);

  if (context) {
    window.history.replaceState({}, '', removeUrlParam('ct', window.location.href));
  }

  return context;
};

/**
 * CSCS Service
 */
export class UcmsService {
  private http!: Http;

  constructor(environment: EnvironmentType) {
    this.http = new Http(getBaseUrl(environment));
    logger.log(`--- Base Url ${getBaseUrl(environment)} ---`);
    logger.log(`--- UcmsService has been initialised pointing to ${environment} environment ---`);
  }

  getToken(): string {
    return getParamContextToken() || getContextToken() || '';
  }

  async getContext(): Promise<UserContext | null> {
    try {
      logger.log('--- Getting context from UCMS ... ---');

      let token = this.getToken();
      if (!token) {
        const data = await this.http.get<ContextTokenResponse>('/context');
        token = typeof data === 'string' ? data : data.token;
      }
      const context = await this.http.get<UserContext>(`/context/${token}`);
      return context;
    } catch (error) {
      logger.error('UCMC could get the remote state', error);
    }

    return null;
  }

  async setContext(context: UserContext): Promise<void> {
    try {
      const token = this.getToken();
      const data = await this.http.post<UserContext, SetContextResponse>(`/context/${token}`, context);
      setContextToken(data.token);
      logger.log('--- Context has been set to UCMS... ---');
    } catch (error) {
      logger.error('UCMC could not persist the state into UCMS', error);
    }
  }
}

export const saveStateToUCMS = (state: B2XActiveProfileState): Promise<void> => {
  // Update UCMS
  const companyId = state.company?.domainRef?.meId;
  const businessType = companyId ? CONTEXT_TYPES.B2B : CONTEXT_TYPES.B2C;
  return updateUCMSContext(businessType, companyId);
};

const updateUCMSContext = async (businessType: CONTEXT_TYPES, companyId?: string) => {
  const aem = await getAemEnvironmentPromise();
  const ucms = new UcmsService(aem.stage.toLowerCase() as EnvironmentType);

  const context = new UserContext(
    { label: businessType as string, contextId: businessType, lastSelectedCompanyProfileId: companyId },
    true,
  );

  logger.log(`Storing UCMS Context : ${context}`);
  return ucms.setContext(context);
};
