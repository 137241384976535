import { AEMComponentPrefill } from '@owc/component-registry-connection';
import { aemComponentRegistryStoreHandler } from './aem-component-registry-store';
import { Deferred } from './deferred';

const deferredAemComponentData = new Deferred<AEMComponentPrefill>();

export async function getAemComponentData(componentId: string): Promise<AEMComponentPrefill> {
  return new Promise((resolve, reject) => {
    const store = aemComponentRegistryStoreHandler.getStore();
    let initialized = false;
    try {
      const listener = (state: Record<string, unknown>) => {
        initialized = true;
        resolve(state as AEMComponentPrefill);
        store.unsubscribe(componentId, listener);
      };

      store.subscribe(componentId, listener);
      setTimeout(() => {
        if (!initialized) {
          // if store doesnt fire anything in 2 seconds - unfreeze
          reject('aemComponentRegistryStore froze for 2s+');
        }
      }, 2000);
    } catch (e) {
      reject('an exception occurred ' + (e || ({} as any)).message);
    }
  });
}

async function initData(componentId: string | null, jsonData: string | null): Promise<void> {
  if (jsonData) {
    const parsedData = JSON.parse(jsonData);
    deferredAemComponentData.resolve(parsedData);
    return;
  }

  if (componentId) {
    let aemComponentData;
    try {
      aemComponentData = await getAemComponentData(componentId);
    } catch (e) {
      aemComponentData = (window as any)[componentId];
    }
    deferredAemComponentData.resolve(aemComponentData);
    return;
  }

  deferredAemComponentData.reject('componentId has to be defined');
}

export const aemComponentDataHandler = {
  initData(componentId: string | null, jsonData: string | null): Promise<AEMComponentPrefill> {
    initData(componentId, jsonData).then(() => {
      // do nothing
    });
    return deferredAemComponentData.promise;
  },
  getData(): Promise<AEMComponentPrefill> {
    return deferredAemComponentData.promise;
  },
};
