import { B2XContext } from '@commons-fe/mmcs-models';

export enum SupportedConsentLabels {
  ONE_BNB = 'ONE_BNB',
  AVV = 'AVV',
  PRODUCT_IMPROVEMENT = 'PRODUCT_IMPROVEMENT',
  PRODUCT_IMPROVEMENT_VOICE = 'PRODUCT_IMPROVEMENT_VOICE',
  PRODUCT_IMPROVEMENT_MAP = 'PRODUCT_IMPROVEMENT_MAP',
  MOBILITY_INFRASTRUCTURE_CONTRIBUTION = 'MOBILITY_INFRASTRUCTURE_CONTRIBUTION',
  ADVERTISEMENT = 'ADVERTISEMENT',
  PRODUCT_IMPROVEMENT_COMPANY = 'PRODUCT_IMPROVEMENT_COMPANY',
  PRODUCT_IMPROVEMENT_VOICE_COMPANY = 'PRODUCT_IMPROVEMENT_VOICE_COMPANY',
  PRODUCT_IMPROVEMENT_MAP_COMPANY = 'PRODUCT_IMPROVEMENT_MAP_COMPANY',
  ADDITIONAL_IT = 'ADDITIONAL_IT',
}

export enum ConsentDisplayStyle {
  LISTITEM = 'LISTITEM', // https://confluence.mercedes-benz.io/display/MMEWEB/%5BCCCPIC%5D+Zustimmung+Produktverbesserungen see Layout "only one option"
  CHECKBOX = 'CHECKBOX', // default value
}

export enum WarningStyle {
  ERROR = 'ERROR',
  HINT = 'HINT',
}

export enum ConsentMode {
  DEFAULT = 'DEFAULT',
  UPCOMING = 'UPCOMING',
}

export interface CustomTranslationKey {
  label: SupportedConsentLabels;
  key: string;
}

export interface ConsentWarning {
  header: string;
  messages: string[];
  style: WarningStyle;
  services?: string[];
  anchor?: {
    url: string;
    text: string;
  };
}

export interface ConsentRuleSet {
  isMandatory: boolean; // Mark Consent as Mandatory. Only if all Mandatory Consents are agreed the widget should be able to save
  showOnAgreed?: boolean; // Can be override by the value of 'showOnlyMandatory'
  consentWarning?: ConsentWarning;
  disableOnMandatoryNotAgreed?: boolean; // disable Checkbox if not all Mandatory Consents are Agreed
  // Value will be overriden with Property 'disableConsentsOnMandatoryConsentNotAgreed'
  // E.g if 'disableConsentsOnMandatoryConsentNotAgreed' is TRUE, this value will not be considered
}

export interface ConsentRules {
  disableConsentsOnMandatoryConsentNotAgreed?: boolean; // https://confluence.mercedes-benz.io/display/MMEWEB/%5BTOUC%5D+Terms+of+Use+-+Manage+Connect+Consents === default true
  showOnlyMandatory?: boolean; // E.g --> true if APDWC / false if vawc & touc === default false
  labelRules?: LabelRules;
}

export type LabelRules = {
  [key in SupportedConsentLabels]?: ConsentRuleSet;
};

export interface InitConfig {
  allowCompanyReset?: boolean;
  showConsentDate?: boolean; // default true
  oneConsentModeStyle: ConsentDisplayStyle; // default Checkbox
  b2xContext: B2XContext;
  consentLabels?: SupportedConsentLabels[];
  finOrVin?: string; // Could be undefined if no vehicle should be considered while loading the consents
  serviceIds?: string[]; // Could be undefined if no services should be considered while loading the consents
  consentMode?: ConsentMode; //Default is 'DEFAULT'
  customTranslationKeys?: CustomTranslationKey[];
  consentRules?: ConsentRules;
}

/**
 * Store state
 */
export enum CMStatus {
  WAITING_FOR_CONFIG = 'WAITING_FOR_CONFIG',
  CONFIG_LOADED = 'CONFIG_LOADED',
  CONSENTS_LOADING_ERROR = 'CONSENTS_LOADING_ERROR',
  CONSENTS_LOADING_SUCCESS = 'CONSENTS_LOADING_SUCCESS',
  SAVING = 'SAVING',
  AGREE_ALL = 'AGREE_ALL',
  CONSENTS_SAVING_SUCCESS = 'CONSENTS_SAVING_SUCCESS',
  CONSENTS_SAVING_ERROR = 'CONSENTS_SAVING_ERROR',
  CONSENTS_SAVED_AND_COMPANY_RESET = 'CONSENTS_SAVED_AND_COMPANY_RESET',
  CONSENTS_SAVING_ABORTED = 'CONSENTS_SAVING_ABORTED',
}

export enum ValidationStatus {
  OPEN = 'OPEN',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface ConsentValidationStatus {
  status: ValidationStatus;
  accepted: boolean;
  isMandatory: boolean;
}

export type ConsentValidationStates = {
  [key in SupportedConsentLabels]?: ConsentValidationStatus;
};

export interface ValidationResult {
  status: ValidationStatus;
  consentStates: ConsentValidationStates;
}

export interface CMStoreData {
  initConfig?: InitConfig;
  status: CMStatus;
  trackingId?: string;
  oldDocumentVersionAgreed: boolean;
  consentCount: number;
  validationResult: ValidationResult;
}

export const localStoreNamespace = 'TOUC'; // used only for local DEV and TEST
