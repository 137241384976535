import { connectionName as aemEnvConnectionName } from '@owc/connection-aem-environment';
import { initializeStore } from '@seamless/store';

export const isEmpty = (obj: any): any =>
  [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

export const isFunction = (func: any) => {
  return typeof func === 'function';
};

export function appendQueryParams(params: Record<any, any>): string {
  if (!params || !Object.keys(params).length) {
    return '';
  }
  const encodedParams = Object.entries(params)
    .map((kv) => kv.map(encodeURIComponent).join('='))
    .join('&');

  return `?${encodedParams}`;
}

export function removeQueryParametersFromUrl(url: string, parameters: string[]): string {
  try {
    const targetUrl = new URL(url);

    parameters.forEach((param) => {
      targetUrl.searchParams.delete(param);
    });

    // This is needed when BIAS returns error code as query param.
    // somehow, the #. were appended to the url, even though BIAS does not send them
    if (targetUrl.hash === '#.') {
      targetUrl.hash = '';
    }

    return targetUrl.href;
  } catch (error) {
    return url;
  }
}

export const getQueryParameter = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(param);
};

export const isAemEnvironment = async (): Promise<boolean> => {
  const store = initializeStore();

  return !!(await store.getConnectionDispatchers(aemEnvConnectionName, 500).catch(() => {
    return false;
  }));
};
