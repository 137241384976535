/* istanbul ignore file */
import { wkoStoreModuleName } from '@commons-fe/wko-vuex-module';
import { cmStoreModuleName } from '@dh-io-mmcs/consents-management-store/vuex';
import { aemStoreModuleName } from '@commons-fe/aem-vuex-module';

export const MODULE_NAMES = {
  WKO: wkoStoreModuleName,
  CM: cmStoreModuleName,
  AEM: aemStoreModuleName,
} as const;
