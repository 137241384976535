import { AxiosRequestConfig } from 'axios';

/**
 * We use MMC-Locale for our logging.
 */
export function getMmcLocaleInterceptor(locale: string): (config: AxiosRequestConfig<any>) => AxiosRequestConfig<any> {
  console.warn(
    'You are using an deprecated getMmcLocaleInterceptor method and MMC-Locale header parameter that will be replaced soon in favor to activeProfileInterceptor and locale header parameter, source: https://git.daimler.com/dh-io-mhb2b/b2xpal/blob/develop/packages/api-interceptors/src/req/active-profile.ts',
  );
  return (config: AxiosRequestConfig<any>) => {
    const headers = {
      ...config.headers,
      'MMC-Locale': locale,
    };

    return {
      ...config,
      headers,
    };
  };
}
