import { RegionMapping } from './region-mapping';

export class ApiConfiguration {
  public static API: string;

  static updateConfiguration(applicationName: string, stage: Stage, country: string | undefined): void {
    if (!stage || !country) {
      throw new Error(`Update of environment configuration failed! Stage: ${stage} Country: ${country}`);
    }
    const region = RegionMapping.toRegion(country);
    this.API = `${apiConfig[region][stage].serverApiUrl}/${applicationName}`;
  }
}

export enum Stage {
  DEV = 'DEV',
  INT = 'INT',
  PPROD = 'PPROD',
  PROD = 'PROD',
}

const apiConfig: any = {
  ECE: {
    DEV: {
      serverApiUrl: 'http://localhost:8383',
    },
    INT: {
      serverApiUrl: 'https://services.me-int.mercedes-benz.com/connect-int',
    },
    PPROD: {
      serverApiUrl: 'https://services.me-int.mercedes-benz.com/connect-pprod',
    },
    PROD: {
      serverApiUrl: 'https://services.me.mercedes-benz.com/connect-prod',
    },
  },
  AMAP: {
    DEV: {
      serverApiUrl: 'http://localhost:8383',
    },
    INT: {
      serverApiUrl: 'https://services-amap.me-int.mercedes-benz.com/connect-int',
    },
    PPROD: {
      serverApiUrl: 'https://services-amap.me-int.mercedes-benz.com/connect-pprod',
    },
    PROD: {
      serverApiUrl: 'https://services-amap.me.mercedes-benz.com/connect-prod',
    },
  },
  CN: {
    DEV: {
      serverApiUrl: 'http://localhost:8383',
    },
    INT: {
      serverApiUrl: 'https://services-china.me-int.mercedes-benz.com/connect-int',
    },
    PPROD: {
      serverApiUrl: 'https://services-china.me-int.mercedes-benz.com/connect-pprod',
    },
    PROD: {
      serverApiUrl: 'https://services.me-prod.mercedes-benz.com.cn/connect-prod',
    },
  },
};
