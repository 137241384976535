import { ActiveUserProfile } from '@dh-io-mhb2b/api-model';

export interface B2XEnsureProperties {
  ensureProperties?: Array<string>;
}

declare const VERSION: string;

export interface HasMeta {
  meta?: Meta;
}

export interface Meta {
  version: string;
  status: MetaState;
}

export type MetaState = 'INITIALIZING' | 'READY';

export type B2XActiveProfileState = ActiveUserProfile & B2XEnsureProperties & HasMeta;
export type B2XActiveProfilePersistedState = B2XActiveProfileState;

export const initialState = () =>
  ({
    market: {},
    locale: {},
    fleets: [],
    vehicles: [],
    roles: [],
    userJourneyCache: {},
    permissions: {
      actionPermissions: [],
      servicePermissions: [],
      entityPermissions: [],
    },
    ensureProperties: [],
    meta: {
      version: VERSION,
      status: 'INITIALIZING',
    },
  } as B2XActiveProfileState);
