/** Utility function to create all necessary vuex paths */
export function buildPaths<Type extends Record<string, unknown>>(
  moduleName: string,
  object: Type,
): Record<keyof Type, string> {
  return Object.entries(object).reduce(
    (paths, [key, value]) => ({ ...paths, [key]: `${moduleName}/${value}` }),
    {},
  ) as Record<keyof Type, string>;
}
