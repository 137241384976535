/* istanbul ignore file */
import Vue from 'vue';
import Vuex from 'vuex';
import { wellKnownObjects, WKOState } from '@commons-fe/wko-vuex-module';
import { aemComponentRegistry, AEMState } from '@commons-fe/aem-vuex-module';
import { cmStore, CMVuexStoreState } from '@dh-io-mmcs/consents-management-store/vuex';
import { MODULE_NAMES } from './modules';
import { DevUtils } from '@commons-fe/mmcs-utils/environment';

export interface CMWWrapperState {
  [MODULE_NAMES.WKO]: WKOState;
  [MODULE_NAMES.CM]: CMVuexStoreState;
  [MODULE_NAMES.AEM]: AEMState;
}

Vue.use(Vuex);

const store = new Vuex.Store<CMWWrapperState>({
  strict: DevUtils.isLocalEnvironment(process.env),
  modules: {
    wellKnownObjects,
    cmStore: cmStore,
    aemComponentRegistry,
  },
});

// Mixin the vuex store to vue to be available in a webcomponent
Vue.mixin({ store });
export default store;
