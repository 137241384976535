import { initializeStore, Subscription } from '@seamless/store';
import { Logger, AuthenticationState, IdentityProvider } from '@b2x/common';

import { AUTHENTICATION_CONNECTION, JWS_ENTRY } from './constants';
import { createFakeJWS, decodeJWS, getLegacyLoginApi, destroyAemState } from './utils';

export class Adapter {
  protected logger = new Logger('ONEWEB-ADAPTER');

  private seamlessStore = initializeStore();

  private modules = getLegacyLoginApi();

  private subscription: Subscription;

  constructor() {
    this.logger.log('Adapter created');

    this.subscription = this.seamlessStore.subscribe(AUTHENTICATION_CONNECTION, this.onAuthenticationEvent.bind(this));
  }

  async onAuthenticationEvent(state: AuthenticationState): Promise<void> {
    if (state.isPending) {
      return;
    }

    const isSeamlessLoggedIn = !!state.claims;
    const isCiasState = state.identityProvider === IdentityProvider.CIAS;
    const isCiamLoggedin = this.modules.ciam.isUserAuthenticated();

    this.logger.log(`Adapter listener, state ${isSeamlessLoggedIn}, AEM login ${isCiamLoggedin}`, state);

    if (!isCiasState) {
      this.logger.log('no seamless state or issuer is not CIAS, go back');
      return;
    }

    if (isSeamlessLoggedIn && !isCiamLoggedin) {
      this.logger.log('user IS logged in, but AEM is NOT logged in, trigger AEM login');

      await this.useLoginChecks(state).then(() => this.logger.log('AEM Init completed'));
    }

    if (!isSeamlessLoggedIn && isCiamLoggedin) {
      this.logger.log('user IS NOT logged in, but AEM IS logged in, trigger AEM logout');

      destroyAemState();
    }
  }

  // as injectAem from POC
  async useLoginChecks(state: AuthenticationState): Promise<any> {
    const { ciam, logincheck } = this.modules;
    const defaultExp = new Date().getTime() / 1000 + 3600;

    return new Promise((resolve) => {
      if (!this.isJwsUpdated(state.jwe)) {
        const jws = createFakeJWS({
          jwe: state.jwe,
          name: state.claims?.name,
          first_name: state.claims?.given_name,
          last_name: state.claims?.family_name,
          gender: undefined,
          email: state.claims?.email,
          phone: undefined,
          roles: undefined,
          exp: state.claims?.exp || defaultExp,
        });

        this.logger.log(`FAKE JWS CREATED : ${jws}`);

        try {
          ciam?.postLogin(jws, state.jwe);
          ciam?.init();
          logincheck?.init();
        } catch (e) {
          this.logger.error(e);
        }
      }

      resolve(state);
    });
  }

  private isJwsUpdated(jwe: string | null): boolean {
    const jws = sessionStorage.getItem(JWS_ENTRY);
    const decoded = decodeJWS(jws);

    return decoded?.jwe === jwe;
  }
}
