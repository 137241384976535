import { initializeStore } from '@seamless/store';

export function getJweData(): Promise<string> {
  const store = initializeStore();
  return new Promise((res) => {
    store.once('B2XCORE_AUTHENTICATION', (state: Record<'jwe', string>) => {
      res(state.jwe || '');
    });
  });
}
