import axios from 'axios';
import { AemEnvironmentHandler } from '@dh-io-mhb2b/aem-services';
import {
  ProfilesApi,
  CompaniesApi,
  PreferredContactsApi,
  Configuration as ProfilesConfiguration,
} from '@dh-io-mhb2b/contracts-profiles';
import {
  CompanyUsersApi,
  CompanyRolesApi,
  Configuration as UsersConfiguration,
} from '@dh-io-mhb2b/contracts-company-users';
import { VehiclesApi, Configuration as VehiclesConfiguration } from '@dh-io-mhb2b/contracts-vehicles';
import { FormsApi, Configuration as FormsConfiguration } from '@dh-io-mhb2b/contracts-forms';
import {
  PermissionsApi,
  ActiveUserProfileApi,
  Configuration as PermissionsConfiguration,
} from '@dh-io-mhb2b/b2x-permissions';
import {
  ActiveProfileInterceptor as ActiveProfileMHB2BInterceptor,
  GetMmcLocaleInterceptor,
  GetMmcMarketInterceptor,
} from '@dh-io-mhb2b/http-interceptors';
import { ActiveProfileInterceptor } from '@dh-io-mhb2b/active-profile';
import {
  GetApplicationNameInterceptor,
  GetBearerTokenInterceptor,
  GetCorsCredentialsInterceptor,
  ContentTypeInterceptor,
  TrackingIdInterceptor,
} from '@dh-io-mhb2b/api-interceptors';

import { configHandler, Config } from './api-config';

export interface ApiInstances {
  profileApi: ProfilesApi;
  companyProfileApi: CompaniesApi;
  companyUsersApi: CompanyUsersApi;
  companyRolesApi: CompanyRolesApi;
  vehiclesApi: VehiclesApi;
  preferredContactsApi: PreferredContactsApi;
  formsApi: FormsApi;
  permissionsApi: PermissionsApi;
  activeUserProfileApi: ActiveUserProfileApi;
}

export async function createAxiosInstance(config: Config, applicationName: string): Promise<any> {
  const axiosInstance = axios.create();
  const { market, locale } = await AemEnvironmentHandler.getMarketAndLocale();

  axiosInstance.interceptors.request.use(ActiveProfileMHB2BInterceptor as any);
  axiosInstance.interceptors.request.use(ActiveProfileInterceptor as any);
  axiosInstance.interceptors.request.use(ContentTypeInterceptor as any);
  axiosInstance.interceptors.request.use(TrackingIdInterceptor as any);
  axiosInstance.interceptors.request.use(GetApplicationNameInterceptor(applicationName) as any);
  axiosInstance.interceptors.request.use(GetBearerTokenInterceptor() as any);
  axiosInstance.interceptors.request.use(GetCorsCredentialsInterceptor(config as any) as any);
  axiosInstance.interceptors.request.use(GetMmcLocaleInterceptor(locale) as any);
  axiosInstance.interceptors.request.use(GetMmcMarketInterceptor(market) as any);

  return axiosInstance;
}

export async function instantiateApi(applicationName: string): Promise<ApiInstances> {
  const config: Config = await configHandler.getConfig();
  const axiosInstance = await createAxiosInstance(config, applicationName);

  return {
    profileApi: new ProfilesApi(
      new ProfilesConfiguration(),
      `${config.apiBasePath}/mhb2b-profile-overview/mhb2b`,
      axiosInstance,
    ),
    companyProfileApi: new CompaniesApi(
      new ProfilesConfiguration(),
      `${config.apiBasePath}/mhb2b-company-profile/mhb2b`,
      axiosInstance,
    ),
    companyUsersApi: new CompanyUsersApi(
      new UsersConfiguration(),
      `${config.apiBasePath}/mhb2b-company-user-management/mhb2b`,
      axiosInstance,
    ),
    companyRolesApi: new CompanyRolesApi(
      new UsersConfiguration(),
      `${config.apiBasePath}/mhb2b-company-user-management/mhb2b`,
      axiosInstance,
    ),
    vehiclesApi: new VehiclesApi(
      new VehiclesConfiguration(),
      `${config.apiBasePath}/mhb2b-vehicle/mhb2b`,
      axiosInstance,
    ),
    preferredContactsApi: new PreferredContactsApi(
      new ProfilesConfiguration(),
      `${config.apiBasePath}/mhb2b-company-profile/mhb2b`,
      axiosInstance,
    ),
    formsApi: new FormsApi(
      new FormsConfiguration(),
      `${config.apiBasePath}/mhb2b-company-profile/mhb2b`,
      axiosInstance,
    ),
    permissionsApi: new PermissionsApi(
      new PermissionsConfiguration(),
      `${config.apiBasePath}/mhb2b-permissions/mhb2b`,
      axiosInstance,
    ),
    activeUserProfileApi: new ActiveUserProfileApi(
      new PermissionsConfiguration(),
      `${config.apiBasePath}/mhb2b-permissions/mhb2b`,
      axiosInstance,
    ),
  };
}

export const apiInstancesHandler = {
  async getInstances(applicationName = 'mhb2b'): Promise<ApiInstances> {
    return await instantiateApi(applicationName);
  },
};
