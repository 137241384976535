import { getApiBaseUrl, RelativePathOptions } from './environments';
import { aemEnvironmentHandler } from '../aem/aem-environment';

export interface Config {
  apiBasePath: string;
  id: string;
}

export async function requestConfig(relativePath?: RelativePathOptions): Promise<Config> {
  const stage = await aemEnvironmentHandler.getStage();
  const country = await aemEnvironmentHandler.getCountry();
  const serverApiUrl = getApiBaseUrl(country, stage, relativePath);

  return {
    apiBasePath: serverApiUrl,
    id: stage,
  };
}

export const configHandler = {
  async getConfig(relativePath?: RelativePathOptions): Promise<Config> {
    return await requestConfig(relativePath);
  },
};
