import { AxiosRequestConfig } from 'axios';

/**
 * we set content-Type to 'application/json' because it is default for rest endpoints
 */
export function contentTypeInterceptor(config: AxiosRequestConfig<any>): AxiosRequestConfig<any> {
  const headers = {
    'Content-Type': 'application/json',
  };

  return {
    ...config,
    headers: {
      ...config.headers,
      ...headers,
    },
  };
}
