/* eslint-disable @typescript-eslint/no-unused-vars */
import { CiamModule, LoginModule } from './types';

export const decodeJWS = (encodedJWT: string | null) => {
  let payload = null;

  if (encodedJWT) {
    const segments = encodedJWT.split('.');

    if (segments.length === 3) {
      // Base64 URL decoding
      let decodedJWT = segments[1].replace(/-/g, '+').replace(/_/g, '/');
      switch (decodedJWT.length % 4) {
        case 0:
          break;
        case 2:
          decodedJWT += '==';
          break;
        case 3:
          decodedJWT += '=';
          break;
        default:
          console.error('Illegal base64url string');
      }
      decodedJWT = window.atob(decodedJWT);

      payload = JSON.parse(decodeURIComponent(escape(decodedJWT)));
    }
  }

  return payload;
};

export const createFakeJWS = (extra: { [x: string]: any } = {}): string => {
  const json = {
    ...extra,
  };
  const segment = btoa(unescape(encodeURIComponent(JSON.stringify(json))))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');

  return `XXX-FAKE-JWS-XXX.${segment}.XXX-FAKE-JWS-XXX`;
};

export const getLegacyLoginApi = (): { ciam: CiamModule; logincheck: LoginModule } => {
  const defaults = {
    get ciam() {
      const isUserAuthenticated = () => false;
      const postLogin = (jws: string, jwe: string) => {
        //Legacy code
      };
      const init = () => {
        //Legacy code
      };
      const postLogout = () => {
        //Legacy code
      };
      const saveConsentId = (id: string) => {
        //Legacy code
      };
      const destroy = () => {
        //Legacy code
      };

      return { isUserAuthenticated, postLogin, postLogout, init, saveConsentId, destroy } as CiamModule;
    },
    get logincheck() {
      const init = () => {
        //Legacy code
      };

      return { init } as LoginModule;
    },
  };

  const ciam = window?.nn?.ciam() || defaults.ciam;
  const logincheck = window?.nn?.logincheck() || defaults.logincheck;
  const modules = { ciam, logincheck };

  return modules;
};

export const destroyAemState = (): void => {
  const modules = getLegacyLoginApi();

  modules.ciam.destroy();
  document.dispatchEvent(new Event('plugincomponent:sendUpdateMessage.nn'));

  const logoutRequestedKey = 'logout-requested';
  window.localStorage.setItem(logoutRequestedKey, 'true');
  window.localStorage.removeItem(logoutRequestedKey);
};
