import { buildPaths } from '@commons-fe/mmcs-utils/vuex';

export const MODULE_NAME = 'aemComponentRegistry';

export const ACTIONS = {
  LOAD_COMPONENT_REGISTRY: 'LOAD_COMPONENT_REGISTRY',
};

export const MUTATIONS = {
  SET_COMPONENT_REGISTRY_VALUES: 'SET_COMPONENT_REGISTRY',
  SET_STATUS: 'SET_STATUS',
};

export const GETTERS = {
  IS_B2B_CONTEXT: 'IS_B2B_CONTEXT',
};

export const ACTION_PATHS = buildPaths(MODULE_NAME, ACTIONS);
export const MUTATION_PATHS = buildPaths(MODULE_NAME, MUTATIONS);
export const GETTERS_PATHS = buildPaths(MODULE_NAME, GETTERS);
