import { MODULE_NAME } from './model';
import { buildPaths } from '@commons-fe/mmcs-utils/vuex';

export const ACTIONS = {
  CONNECT_TO_CM_STORE: 'CONNECT_TO_CM_STORE',
  DISCONNECT_CM_STORE: 'DISCONNECT_CM_STORE',
  SET_CM_STATUS: 'SET_CM_STATUS',
  SET_CM_INITCONFIG: 'SET_CM_INITCONFIG',
  SET_CM_TRACKING_ID: 'SET_CM_TRACKING_ID',
  SET_CM_OLD_DOCUMENT_VERSION_AGREED: 'SET_OLD_DOCUMENT_VERSION_AGREED',
  SET_CM_CONSENT_COUNT: 'SET_CONSENT_COUNT',
  SET_CM_VALIDATION_RESULT: 'SET_VALIDATION_RESULT',
};

export const MUTATIONS = {
  SET_NAMESPACE: 'SET_NAMESPACE',
  SET_SUBSCRIPTION: 'SET_SUBSCRIPTION',
  UPDATE_FROM_SEAMLESS_STATE: 'UPDATE_STATE',
};

export const ACTION_PATHS = buildPaths(MODULE_NAME, ACTIONS);
