import { initializeLogger } from '@seamless/logger';

const B2XLogNamespace = 'B2X';

export class Logger {
  private context = '';

  private delimiter = '▸';

  private _logger;

  constructor(context = '') {
    this._logger = initializeLogger(B2XLogNamespace);

    this.setContext(context);
  }

  setContext(context: string): void {
    this.context = context;
  }

  setDelimiter(delimiter: string): void {
    this.delimiter = delimiter;
  }

  log(...args: unknown[]): void {
    this._logger.log(this.context, this.delimiter, ...args);
  }

  error(...args: unknown[]): void {
    this._logger.error(this.context, this.delimiter, ...args);
  }

  debug(...args: unknown[]): void {
    this._logger.debug(this.context, this.delimiter, ...args);
  }

  warn(...args: unknown[]): void {
    this._logger.warn(this.context, this.delimiter, ...args);
  }
}
