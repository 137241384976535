import { BiasClaims, IdentityProvider, Logger, Stages } from '@b2x/common';
import Cookies from 'js-cookie';

import {
  AUTH_LOGIN_ACTION,
  AUTH_LOGIN_ACTION_PENDING,
  AUTH_LOGOUT_ACTION,
  AUTH_PERSIST_STATE,
  AUTH_SET_ALERT_ACTION,
} from '../../common/actions';
import { biasConfig, CONDITION_TYPE, COOKIE_NAME, vsConfig } from '../../common/constants';
import { appendQueryParams, removeQueryParametersFromUrl } from '../../common/helpers';
import { getJwe } from '../../common/selectors';
import { Client, ClientConditionApi, LoginOptions } from '../../types/auth';
import { httpClient } from '../api';
import { Exception, InvalidLogin, InvalidLoginValidation } from '../errors/exceptions';
import { Settings } from '../settings';

export class BiasAuthClient implements Client {
  public settings: Settings;

  protected logger = new Logger('BIAS_CLIENT');

  identityProvider = IdentityProvider.BIAS;

  constructor(settings: Settings) {
    this.settings = settings;
  }

  login(): void {
    const { redirectUrl } = this.settings.getItem('loginOptions') as LoginOptions;

    const authParams = {
      tenant: this.settings.getItem('tenantId') || biasConfig.tenantId,
      redirectUrl,
    };
    const stage = this.settings.getItem('stage') as Stages;
    const loginUrl = `${biasConfig.envs[stage]}/authentication/${appendQueryParams(authParams)}`;

    this.logger.debug('Login action on BIAS to url:', loginUrl);

    location.href = loginUrl;
  }

  logout(): void {
    const stage = this.settings.getItem('stage') as Stages;
    const logoutUrl = `${biasConfig.envs[stage]}/logout`;
    this.logger.debug('Logout action BIAS redirecting to url:', logoutUrl);

    location.href = logoutUrl;
  }

  async executeCondition(api: ClientConditionApi): Promise<void> {
    const { conditions, dispatch, getAction } = api;
    const { type, token, state, error, isPrivatePage } = conditions;

    try {
      switch (type) {
        case CONDITION_TYPE.AUTHENTICATING: {
          this.logger.debug('BIAS OTT is available', token);

          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, true));

          const cleanUrl = removeQueryParametersFromUrl(window.location.href, ['ott']);
          window.history.replaceState(null, '', cleanUrl);

          const jwe = await this.fetchJwe(token as string).catch(() => {
            throw new InvalidLogin();
          });

          const claims = await this.fetchClaims(jwe).catch(() => {
            throw new InvalidLogin();
          });

          Cookies.set(COOKIE_NAME, 'ON');

          dispatch(getAction(AUTH_LOGIN_ACTION, { jwe, claims }));
          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, false));

          break;
        }

        case CONDITION_TYPE.AUTHENTICATED: {
          this.logger.debug('Auth present in storage, validate it');

          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, true));

          const jwe = getJwe(state);

          await this.validateJwe(jwe as string)
            .then(() => {
              dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, false));
            })
            .catch(() => {
              dispatch(getAction(AUTH_LOGOUT_ACTION, IdentityProvider.BIAS));

              Cookies.remove(COOKIE_NAME);

              if (isPrivatePage) {
                this.login();
              }
            });

          break;
        }

        case CONDITION_TYPE.ERROR: {
          // https://...sales-mode.html?error_code=403.1000&error_description=error.validation.authorization&exception_id=c1f5663b-430c-4e15-b2cb-409c0f62072a#.
          const cleanUrl = removeQueryParametersFromUrl(window.location.href, Object.keys(error));
          window.history.replaceState(null, '', cleanUrl);

          let exception = new InvalidLogin();

          if (error.error_description === 'error.validation.authorization') {
            exception = new InvalidLoginValidation();
          }

          throw exception;
        }

        // Defaults to Public condition
        default: {
          this.logger.debug('BIAS Anonymous case');

          dispatch(getAction(AUTH_PERSIST_STATE, conditions.state));

          if (isPrivatePage) {
            this.login();
          }
        }
      }
    } catch (error) {
      dispatch(getAction(AUTH_LOGOUT_ACTION, IdentityProvider.BIAS));

      const alert = error instanceof Exception ? error.toObject() : Exception.fromError(error as Error);

      dispatch(getAction(AUTH_SET_ALERT_ACTION, alert));

      if (isPrivatePage) {
        location.href = location.origin;
      }
    }
  }

  fetchJwe(ott: string): Promise<string> {
    const stage = this.settings.getItem('stage') as Stages;
    const baseUrl = biasConfig.envs[stage] as string;

    return httpClient.fetchJwe(ott, baseUrl, IdentityProvider.BIAS);
  }

  fetchClaims(jwe: string): Promise<BiasClaims> {
    const stage = this.settings.getItem('stage') as Stages;
    const baseUrl = vsConfig.envs[stage] as string;

    return httpClient.fetchClaims(jwe, baseUrl, IdentityProvider.BIAS);
  }

  validateJwe(jwe: string): Promise<BiasClaims> {
    const stage = this.settings.getItem('stage') as Stages;
    const baseUrl = vsConfig.envs[stage] as string;

    return httpClient.validateJwe(jwe, baseUrl, IdentityProvider.BIAS);
  }
}
