import store from '@/store';
import CMWState from '@/store/cmw-state';
import { Component, Vue } from 'vue-property-decorator';
import { aemStoreGetters } from '@commons-fe/aem-vuex-module';

@Component({
  name: 'header-component',
})
export default class Header extends Vue {
  i18n = this.$i18n;

  get isContentReady(): boolean {
    return !!CMWState.cmStore.consentCount;
  }

  get isTaiwan(): boolean {
    return CMWState.wkoStore.wko?.Locale?.country === 'TW';
  }

  get isB2B(): boolean {
    return store.getters[aemStoreGetters.IS_B2B_CONTEXT];
  }
}
