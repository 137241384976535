import { IdentityProvider, Logger, TENANT } from '@b2x/common';
import { initializeStore, SeamlessConnection } from '@seamless/store';
import { Reducer } from 'redux';

import { AUTHENTICATION_CONNECTION, AUTHENTICATION_CONNECTION_BIAS } from '../common/constants';
import { AsyncThunk, AuthenticationDispatchers } from '../types/auth';

type AuthStateBias = Record<string, undefined>;

export type AuthDispatchersBias = {
  initAuthentication: () => void;
  login: () => void;
  logout: () => void;
  setInitParams: () => void;
};

export class AuthConnectionBias extends SeamlessConnection<AuthDispatchersBias, AuthStateBias> {
  protected logger = new Logger(AUTHENTICATION_CONNECTION_BIAS);

  seamlessStore = initializeStore();

  constructor(connectionName = AUTHENTICATION_CONNECTION_BIAS) {
    super(connectionName);
  }

  get initialState(): AuthStateBias {
    return {};
  }

  getReducer(): Reducer {
    return (state: AuthStateBias): AuthStateBias => state;
  }

  getPublicDispatchers(): AuthDispatchersBias {
    return {
      initAuthentication: () => ({ type: 'EMPTY' }),
      login: this.loginAction.bind(this),
      logout: this.logoutAction.bind(this),
      setInitParams: () => ({ type: 'EMPTY' }),
    };
  }

  loginAction(): AsyncThunk {
    return async () => {
      const dispatchers = await this.seamlessStore.getConnectionDispatchers<AuthenticationDispatchers>(
        AUTHENTICATION_CONNECTION,
      );

      dispatchers.login({
        identityProvider: IdentityProvider.BIAS,
        tenantId: TENANT.OFLOGIN,
      });
    };
  }

  logoutAction(): AsyncThunk {
    return async () => {
      const dispatchers = await this.seamlessStore.getConnectionDispatchers<AuthenticationDispatchers>(
        AUTHENTICATION_CONNECTION,
      );

      dispatchers.logout();
    };
  }
}
