import { initializeComponentRegistry } from '@owc/component-registry-connection';
import { AemComponentRegistry } from './aemComponentRegistry.model';

function _getComponentId(tagName: string): string | null {
  const component = document.querySelector(tagName);
  return component?.getAttribute('component-id') || null;
}

export function loadComponentRegistry(tagName: string): Promise<AemComponentRegistry> {
  return new Promise<AemComponentRegistry>((resolve, reject) => {
    const owcComponentRegistry = initializeComponentRegistry();
    const componentId = _getComponentId(tagName);

    if (componentId !== null) {
      const listener = (aemComponentRegistry: unknown) => {
        owcComponentRegistry.unsubscribe(componentId, listener);
        if (aemComponentRegistry) {
          resolve(aemComponentRegistry as AemComponentRegistry);
        } else {
          reject('Could not fetch component registry from AEM');
        }
      };

      owcComponentRegistry.subscribe(componentId, listener);
    } else {
      reject(`Could not find component id for tag name '${tagName}'`);
    }
  });
}
