import { createSelector } from 'reselect';
import { B2XActiveProfileState } from './state';

export const B2B_BUSINESS_TYPE = 'B2B';
export const B2C_BUSINESS_TYPE = 'B2C';
export const BusinessTypes = [B2B_BUSINESS_TYPE, B2C_BUSINESS_TYPE];
export type BusinessType = typeof BusinessTypes[number];

export interface BasicInfo {
  businessType: BusinessType;
  customerGroupId: string;
}

export const customerGroupSelector = (state: B2XActiveProfileState) => state.activeCustomerGroup;

export const userSelector = (state: B2XActiveProfileState) => state.user;

export const companySelector = (state: B2XActiveProfileState) => state.company;

export const customerGroupIdSelector = createSelector([customerGroupSelector], (group) => group?.domainRef?.id);

export const customerTypeSelector = createSelector([customerGroupSelector], (group) => group?.customerType);

export const businessTypeSelector = createSelector(
  [customerTypeSelector],
  (ct): BusinessType | undefined => ct?.businessType,
);

const isEmpty = <X>(x?: X) => !!x && Object.keys(x).length === 0;

export const isAnonymousSelector = createSelector(
  [businessTypeSelector, userSelector, companySelector],
  (businessType, user, company) => {
    return (
      (businessType === B2B_BUSINESS_TYPE && isEmpty(company)) || (businessType === B2C_BUSINESS_TYPE && isEmpty(user))
    );
  },
);

export const basicInfoSelector = createSelector(
  [businessTypeSelector, customerGroupIdSelector],
  (businessType, customerGroupId) =>
    ({
      businessType,
      customerGroupId,
    } as BasicInfo),
);
