import { Alert, AuthenticationState, BiasClaims, CiasClaims, IdentityProvider, UserProfile } from '@b2x/common';
import { createSelector } from 'reselect';

/**
 * Retrieve from state the actual login value
 */
export const isLoggedIn = (state: AuthenticationState): boolean => {
  const selector = createSelector(
    (state: AuthenticationState) => state.claims,
    (claims) => !!claims,
  );

  return selector(state);
};

/**
 * Retrieve from state actual user claims if loggedin otherwise it will be null
 */
export const getClaims = (state: AuthenticationState): CiasClaims | BiasClaims | null => {
  const selector = createSelector(
    (state: AuthenticationState) => state.claims,
    (claims) => claims,
  );

  return selector(state);
};

export const getJwe = (state: AuthenticationState): string | null => {
  const selector = createSelector(
    (state: AuthenticationState) => state.jwe,
    (jwe) => jwe,
  );

  return selector(state);
};

export const getAlert = (state: AuthenticationState): Alert | null => {
  const selector = createSelector(
    (state: AuthenticationState) => state.alert,
    (alert) => alert,
  );

  return selector(state);
};

export const getIdentityProvider = (state: AuthenticationState): IdentityProvider | null => {
  const selector = createSelector(
    (state: AuthenticationState) => state.identityProvider,
    (identityProvider) => identityProvider,
  );

  return selector(state);
};

export const getUserProfile = (state: AuthenticationState): UserProfile | null => {
  const selector = createSelector(
    (state: AuthenticationState) => state.profile,
    (profile) => profile,
  );

  return selector(state);
};

export const isPending = (state: AuthenticationState): boolean => {
  const selector = createSelector(
    (state: AuthenticationState) => state.isPending,
    (isPending) => isPending,
  );

  return selector(state);
};
