import { v4 as uuidv4 } from 'uuid';
import { UserJourneyCache } from '@dh-io-mhb2b/api-model';

export function generateNewUserJourneyCache(): UserJourneyCache {
  return {
    domainRef: {
      id: uuidv4(),
    },
  }
}
