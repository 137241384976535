import { SeamlessConnection } from '@seamless/store';
import { OwcTranslationDispatchers, OwcTranslationManagerState, TranslationProject } from './types';

// the reducer connection name
export const connectionName = 'MBMXP_OWC_TRANSLATION_MANAGER';
// Action const
const PUSH_TRANSLATION_MESSAGE = 'PUSH_TRANSLATION_MESSAGE';
const initialState: OwcTranslationManagerState = {};

class OwcTranslationManagerConnection extends SeamlessConnection<
  OwcTranslationDispatchers,
  OwcTranslationManagerState
> {
  constructor() {
    super(connectionName);
  }

  get initialState() {
    return initialState;
  }

  getReducer() {
    return (state: any, action: any) => {
      switch (action.type) {
        case this.getActionType(PUSH_TRANSLATION_MESSAGE):
          if (state[action.payload.projectID]) {
            return {
              ...state,
              [action.payload.projectID]: { ...state[action.payload.projectID], ...action.payload.translation },
            };
          }
          return {
            ...state,
            [action.payload.projectID]: action.payload.translation,
          };
        default:
          return state;
      }
    };
  }

  getPublicDispatchers() {
    return {
      pushTranslationMessage: (projectID: string, translation: TranslationProject) => {
        return this.getAction(PUSH_TRANSLATION_MESSAGE, { projectID, translation });
      },
    };
  }
}

export const connection = new OwcTranslationManagerConnection();
