import { IdentityProvider, Logger, TENANT } from '@b2x/common';

import { QUERY_PARAMS } from '../common/constants';
import { removeQueryParametersFromUrl } from '../common/helpers';
import { AuthenticationParams } from '../types/auth';
import { Exception } from './errors/exceptions';

const defaultSettings: AuthenticationParams = {
  identityProvider: IdentityProvider.CIAS,
  country: 'DE',
  language: 'de',
  stage: undefined,
  tenantId: '',
  loginOptions: {
    redirectUrl: removeQueryParametersFromUrl(location.href, Object.values(QUERY_PARAMS)),
  },
  logoutOptions: {
    targetUrl: removeQueryParametersFromUrl(location.href, Object.values(QUERY_PARAMS)),
  },
};

export type SettingsKeys =
  | 'identityProvider'
  | 'country'
  | 'language'
  | 'stage'
  | 'tenantId'
  | 'loginOptions'
  | 'logoutOptions';

export class Settings {
  private options = new Map<SettingsKeys, AuthenticationParams[SettingsKeys]>();
  protected logger = new Logger('SETTINGS');

  get locale(): string {
    return `${this.getItem('language', 'de')}_${this.getItem('country', 'DE')}`;
  }

  get isOneWeb(): boolean {
    return this.getItem('tenantId', null) === TENANT.ONEWEB;
  }

  constructor(options: AuthenticationParams) {
    const settings = {
      ...defaultSettings,
      ...options,
    };

    Object.entries(settings).forEach(([key, value]) => this.setItem(key as SettingsKeys, value));

    Exception.locale = this.locale;

    this.log();
  }

  getItem(key: SettingsKeys, defaults: string | null = null): AuthenticationParams[SettingsKeys] | null {
    return this.options.has(key) ? this.options.get(key) : defaults;
  }

  setItem(key: SettingsKeys, value: unknown): void {
    this.options.set(key, value as string | undefined);
  }

  hasItem(key: SettingsKeys): boolean {
    return this.options.has(key);
  }

  getItems(): AuthenticationParams {
    const list = Object.create(null);

    this.options.forEach((value, key) => {
      Object.defineProperty(list, key, { value, enumerable: true });
    });

    return list;
  }

  log(): void {
    this.logger.debug('Authentication params:', {
      Authority: this.getItem('identityProvider'),
      Locale: this.locale,
      Tenant: this.getItem('tenantId'),
      Stage: this.getItem('stage'),
      OneWeb: this.isOneWeb,
    });
  }
}
