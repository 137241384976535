const englishMessages = {
  ERROR_GENERIC: 'Unexpected error occurred in login component.',
  ERROR_LOGIN_GENERIC: 'Login failed. Please try again later.',
  ERROR_LOGIN_SESSION_EXISTS:
    'Already logged in via another authentication provider. Please logout first and then try again.',
  ERROR_LOGIN_VALIDATION_AUTHORIZATION:
    'Please refer to the administrator of the application to get access granted for your user ID.',
  ERROR_LOGIN_PROFILE_GENERIC: 'User profile could not be retrieved.',
  ERROR_LOGOUT_GENERIC: 'Logout failed. Please try again later.',
};

const germanMessages = {
  ERROR_GENERIC: 'Unerwarteter Fehler in der Anmeldekomponente aufgetreten.',
  ERROR_LOGIN_GENERIC: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
  ERROR_LOGIN_SESSION_EXISTS:
    'Sie sind bereits über einen anderen Authentifizierungsanbieter eingeloggt. Bitte loggen Sie sich zuerst aus und versuchen Sie es dann erneut.',
  ERROR_LOGIN_VALIDATION_AUTHORIZATION:
    'Sie haben aktuell keine Berechtigung für die Benutzung dieser Funktionalität. Bitte wenden Sie sich an den Administrator der Anwendung und bitten Ihn um Freischaltung für Ihre Benutzerkennung.',
  ERROR_LOGIN_PROFILE_GENERIC: 'Das Benutzerprofil konnte nicht abgerufen werden.',
  ERROR_LOGOUT_GENERIC: 'Abmeldung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
};

export const DEFAULT_LOCALE = 'en_GB';

export const I18N_TRANSLATIONS: Record<string, Record<string, string>> = {
  de_DE: {
    ...germanMessages,
  },
  de_AT: {
    ...germanMessages,
  },
  de_CH: {
    ...germanMessages,
  },
  de_AQ: {
    ...germanMessages,
  },
  de_LI: {
    ...germanMessages,
  },
  de_LU: {
    ...germanMessages,
  },
  en_GB: {
    ...englishMessages,
  },
};
