export const ActionTypes = {
  SET_MARKET: 'SET_MARKET',
  SET_LOCALE: 'SET_LOCALE',
  SET_COMPANY: 'SET_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY', // internal use from store
  SET_USER: 'SET_USER',
  SET_FLEETS: 'SET_FLEETS',
  SET_VEHICLES: 'SET_VEHICLES',
  SET_VEHICLE_COUNT: 'SET_VEHICLE_COUNT',
  SET_ROLES: 'SET_ROLES',

  SET_ACTION_PERMISSIONS: 'SET_ACTION_PERMISSIONS',
  SET_SERVICE_PERMISSIONS: 'SET_SERVICE_PERMISSIONS',
  SET_ENTITY_PERMISSIONS: 'SET_ENTITY_PERMISSIONS',

  SET_USER_JOURNEY_CACHE: 'SET_USER_JOURNEY_CACHE',
  REGENERATE_USER_JOURNEY_CACHE: 'REGENERATE_USER_JOURNEY_CACHE',

  SET_ACTIVE_COSTUMER_GROUP: 'SET_ACTIVE_COSTUMER_GROUP',
  SET_STATE: 'SET_STATE',
  RESET_STATE: 'RESET_STATE',
  ENSURE_PROPERTIES: 'ENSURE_PROPERTIES',
};
