import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getTranslation } from '@owc/connection-aem-translations';

Vue.use(VueI18n);

const PROJECT_ID = '25afc353ed6d9fcc833fcd65ae519954';

export const i18nService = new VueI18n({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
});

export async function loadTranslation(locale: string): Promise<void> {
  // Set your current locale for i18n service
  i18nService.locale = locale;

  // Fetch your translations from Phrase API
  const message = await getTranslation(PROJECT_ID, locale);

  // Add the retrieved translations to your i18n service
  i18nService.setLocaleMessage(locale, message);
}
