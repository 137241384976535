// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { initializeStore } from '@seamless/store';
import { initializeLogger } from '@seamless/logger';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EventApp, TrackingEventName, TrackingEvent, TrackingReducerConnection, OwcTrackingDispatchers } from './types';
import { connection, connectionName } from './owc-tracking-manager-connection';
declare const VERSION: string;

/**
 * the connection interface
 * let the devs know which actions are available
 * @export
 * @interface TrackingReducerConnection
 * @extends {Connection}
 */

declare global {
  interface Window {
    dataLayer_ow: TrackingEvent[];
  }
}

const version = VERSION;

// the reducer connection name please use getTrackingReducerConnection() if you need the connection
const LOGGER_NAMESPACE = `OWC FE LIBS ${connectionName}`;
const checkDataLayerAvailability = (): Promise<void> | undefined => {
  if (window.top?.dataLayer_ow) {
    return;
  }
  const logger = initializeLogger(LOGGER_NAMESPACE);
  logger.warn('window.dataLayer_ow is not defined, adding empty Object');
  return new Promise((resolve) => {
    let localDataLayer: any;
    Object.defineProperty(window.top, 'dataLayer_ow', {
      get() {
        return localDataLayer;
      },
      set(newValue) {
        localDataLayer = newValue;
        resolve();
      },
    });
  });
};

/**
 * add a connection to the seamless store
 *
 *
 *     import { addTrackingReducerConnection, connectionName } from '@owc/tracking-reducer-reducer';
 *     import { initializeStore } from '@seamless/store';
 *     // create connection
 *     addTrackingReducerConnection().then((connection) => )...
 * @export
 * @function addTrackingReducerConnection
 */
export const addTrackingReducerConnection = async (): Promise<void> => {
  const store = initializeStore();
  await checkDataLayerAvailability();
  return store.addConnection(connection);
};

/**
 * the dispatchers from the trackingReducer connection
 *
 *      javascript
 *      import { getTrackingDispatchers, connectionName } from '@owc/tracking-reducer-reducer';
 *
 *      // create connection
 *      getTrackingDispatchers().then((dispatchers) => dispatchers.fetchList() ...
 *
 * @export
 * @function getTrackingDispatchers
 * @return Promise<OwcTrackingDispatchers>
 */
export const getTrackingDispatchers = (): Promise<OwcTrackingDispatchers> => {
  const store = initializeStore();
  return store.getConnectionDispatchers<OwcTrackingDispatchers>(connectionName).then((dispatchers) => {
    return dispatchers;
  });
};
/**
 * the connection from the trackingReducer connection.
 * @deprecated It will be removed in a near future.
 * @see getTrackingDispatchers
 * @export
 * @function getTrackingReducerConnection
 */
export const getTrackingReducerConnection = getTrackingDispatchers;
// exporting types
export { EventApp, TrackingEventName, TrackingEvent, TrackingReducerConnection };
