import { AemEnvironmentHandler } from '@dh-io-mhb2b/aem-services';
import { getApiBaseUrl } from './environments';

export interface Config {
  apiBasePath: string;
  id: string;
}

export async function requestConfig(): Promise<Config> {
  const aemEnvironment = await AemEnvironmentHandler.getAemEnvironment();
  const { market } = await AemEnvironmentHandler.getMarketAndLocale();
  const { serverApiUrl } = getApiBaseUrl(market, aemEnvironment.stage);
  return {
    apiBasePath: serverApiUrl,
    id: aemEnvironment.stage,
  };
}

const configPromise = requestConfig();

export const configHandler = {
  async getConfig(): Promise<Config> {
    return configPromise;
  },
};
