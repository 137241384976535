import { AxiosRequestConfig } from 'axios';
import { loadActiveProfileFromStore } from '../../active-profile-store/utils/utils-state';
import { aemEnvironmentHandler } from '../../aem/aem-environment';

export async function activeProfileInterceptorStructure() {
  const activeProfileHeader: any = {};
  const activeProfile = await loadActiveProfileFromStore();
  const { market, locale } = activeProfile || {};

  // Enforce the market and locale to be present
  let loadedMarket = { ...market };
  if (!loadedMarket || !loadedMarket.domainRef) {
    loadedMarket = {
      domainRef: {
        code: await aemEnvironmentHandler.getCountry(),
      },
    };
  }
  activeProfileHeader.market = loadedMarket;

  let loadedLocale = { ...locale };
  if (!loadedLocale || !loadedLocale.country || !loadedLocale.language) {
    loadedLocale = {
      country: await aemEnvironmentHandler.getCountry(),
      language: await aemEnvironmentHandler.getLanguage(),
    };
  }
  activeProfileHeader.locale = loadedLocale;

  if (activeProfile?.user?.domainRef) {
    const { company, roles, userJourneyCache } = activeProfile;

    if (company && company.domainRef) {
      activeProfileHeader.company = {
        domainRef: company?.domainRef,
      };
    }

    if (userJourneyCache && userJourneyCache.domainRef) {
      activeProfileHeader.userJourneyCache = {
        domainRef: userJourneyCache?.domainRef,
      };
    }

    if (roles && roles.length) {
      activeProfileHeader.roles = roles.map((role: any) => ({
        domainRef: role.domainRef,
        context: role.context,
      }));
    }
  }

  return activeProfileHeader;
}

// TODO, this should be placed in the api-interceptors package, not done so far because of cycle dependency issues
export async function activeProfileInterceptor(config: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> {
  const activeProfileHeader = await activeProfileInterceptorStructure();

  const headers = {
    ...config.headers,
    'B2X-ActiveProfile': JSON.stringify(activeProfileHeader),
  };

  return {
    ...config,
    headers,
  };
}
