import { Reducer } from 'redux';
import { SeamlessConnection } from '@seamless/store';
import { InitConfig, CMStatus, ValidationStatus, ValidationResult } from '../shared/model';
import { ActionTypes, CMSConnectionName, CMSeamlessStoreState, CMStoreAction, CMStoreDispatchers } from './model';
import { logger } from '../shared/utils/seamless.utils';

export class CMSConnection extends SeamlessConnection<CMStoreDispatchers, CMSeamlessStoreState> {
  constructor(private readonly namespace: string) {
    super(CMSConnectionName);
  }

  get initialState(): CMSeamlessStoreState {
    return {
      [this.namespace]: {
        status: CMStatus.WAITING_FOR_CONFIG,
        oldDocumentVersionAgreed: false,
        consentCount: 0,
        validationResult: {
          status: ValidationStatus.OPEN,
          consentStates: {},
        },
      },
    };
  }

  protected getReducer(): Reducer {
    return (state: CMSeamlessStoreState, { type, payload }: CMStoreAction) => {
      if (!payload || !payload.namespace) {
        logger.warn('Action with missing namespace or data dispatched! Returning unchanged state');
        return state;
      }
      switch (type) {
        case ActionTypes.UPDATE_CONFIG:
          return {
            ...state,
            [payload.namespace]: {
              ...state[payload.namespace],
              initConfig: payload.data,
            },
          };
        case ActionTypes.UPDATE_STATUS:
          return {
            ...state,
            [payload.namespace]: {
              ...state[payload.namespace],
              status: payload.data,
            },
          };
        case ActionTypes.UPDATE_TRACKING_ID:
          return {
            ...state,
            [payload.namespace]: {
              ...state[payload.namespace],
              trackingId: payload.data,
            },
          };
        case ActionTypes.UPDATE_OLD_DOCUMENT_VERSION_AGREED:
          return {
            ...state,
            [payload.namespace]: {
              ...state[payload.namespace],
              oldDocumentVersionAgreed: payload.data,
            },
          };
        case ActionTypes.UPDATE_CONSENT_COUNT:
          return {
            ...state,
            [payload.namespace]: {
              ...state[payload.namespace],
              consentCount: payload.data,
            },
          };
        case ActionTypes.UPDATE_VALIDATION_RESULT:
          return {
            ...state,
            [payload.namespace]: {
              ...state[payload.namespace],
              validationResult: payload.data,
            },
          };
        default:
          logger.warn(`Action with invalid type ${type} dispatched! Returning unchanged state`);
          return state;
      }
    };
  }

  getPublicDispatchers(): CMStoreDispatchers {
    return {
      updateConfig: (config: InitConfig, namespace: string) => ({
        type: ActionTypes.UPDATE_CONFIG,
        payload: { data: config, namespace },
      }),
      updateStatus: (status: CMStatus, namespace: string) => ({
        type: ActionTypes.UPDATE_STATUS,
        payload: { data: status, namespace },
      }),
      updateTrackingId: (trackingId: string, namespace: string) => ({
        type: ActionTypes.UPDATE_TRACKING_ID,
        payload: { data: trackingId, namespace },
      }),
      updateOldDocumentVersionAgreed: (aggreed: boolean, namespace: string) => ({
        type: ActionTypes.UPDATE_OLD_DOCUMENT_VERSION_AGREED,
        payload: { data: aggreed, namespace },
      }),
      updateConsentCount: (count: number, namespace: string) => ({
        type: ActionTypes.UPDATE_CONSENT_COUNT,
        payload: { data: count, namespace },
      }),
      updateValidationResult: (validationResult: ValidationResult, namespace: string) => ({
        type: ActionTypes.UPDATE_VALIDATION_RESULT,
        payload: { data: validationResult, namespace },
      }),
    };
  }
}
