import { CustomerBusinessType, ActiveUserProfile, Company, User } from '@dh-io-mhb2b/api-model';

import { apiHandler } from '../../api/api-service';
import { B2XActiveUserDispatchers } from '../dispatchers';
import { getActiveProfileDispatchers, loadActiveProfileFromStore } from './utils-state';
import { aemEnvironmentHandler } from '../../aem/aem-environment';
import { B2XActiveProfileState, initialState } from '../state';
import { B2B_BUSINESS_TYPE } from '../selectors';
import { saveStateToBackend } from './utils-persist-backend-save';
import { initializeLogger } from '@seamless/logger';

export interface ActiveUserRequest {
  userToken?: string;
  businessType?: CustomerBusinessType;
  marketId?: string;
  lastSelectedCompanyProfileId?: string;
  ensureProperties?: string[];
}

declare const VERSION: string;
const logger = initializeLogger('B2X-PAL');

export const setStateToStore = async (loadedProfile: B2XActiveProfileState): Promise<void> => {
  const currentState = await loadActiveProfileFromStore();
  const dispatchers: B2XActiveUserDispatchers = await getActiveProfileDispatchers();
  const { market, locale, user, roles, fleets, vehicles, activeCustomerGroup, company, permissions, meta } =
    loadedProfile;
  // We use current state instead of initial state, so some information, like vehicles are not overwritten
  // We know that this may cause inconsistencies in the state, however, it's an accepted risk for now
  const state: B2XActiveProfileState = { ...currentState, meta, market, locale, activeCustomerGroup, company };
  if (user) {
    state.user = user;
  }

  if (permissions?.actionPermissions?.length) {
    state.permissions = {
      ...state.permissions,
      actionPermissions: permissions?.actionPermissions,
    };
  }

  if (roles && roles.length) {
    state.roles = roles;
  }

  if (fleets?.length) {
    state.fleets = fleets;
  }

  if (vehicles?.length) {
    state.vehicles = vehicles;
  }

  dispatchers.setState(state);
};

const activeUserProfile = async ({
  userToken, // if not set, it will use the default one from interceptor
  businessType,
  marketId, // if not set, it will use the default one from AEM
  ensureProperties,
}: ActiveUserRequest) => {
  const { activeUserProfileApi } = await apiHandler.getInstances('MHB2B');
  const headerOptions = userToken ? { 'authorization-jwe': `Bearer ${userToken}` } : {};

  const { data } = await activeUserProfileApi.getActiveUserProfile(
    {
      requests: [
        {
          fspRequest: {
            filterDescriptor: {
              entityType: 'ActiveUserProfile',
            },
            includeDescriptor: {
              includedPropertyPaths: [
                ...new Set([
                  'activeCustomerGroup.**',
                  'company.domainRef.**',
                  'company.customerGroups.customerGroupId.**',
                  'company.name',
                  'company.displayCompanyId',
                  'company.businessType',
                  'company.taxNumber',
                  'company.countryCode',
                  'company.legalForm.**',
                  'company.address.**',
                  'company.invoiceAddress.**',
                  'locale.**',
                  'market.**',
                  'permissions.actionPermissions.domainRef.**',
                  'roles.domainRef.**',
                  'user.domainRef.**',
                  'user.displayName',
                  'user.firstName',
                  'user.lastName1',
                  'user.displayPhoneNumber',
                  'user.email',
                  'user.isVerified',
                  'user.profilePicture',
                  'user.companies.domainRef.**',
                  'vehicles.domainRef.**',
                  ...(ensureProperties || []),
                ]),
              ],
            },
          },
          marketId: { code: marketId || (await aemEnvironmentHandler.getCountry()) },
          customerBusinessType: businessType,
        },
      ],
    },
    headerOptions as any,
  );

  return data;
};

export const loadLastState = async ({
  userToken, // if not set, it will use the default one from interceptor
  businessType,
  marketId, // if not set, it will use the default one from AEM
  lastSelectedCompanyProfileId,
  ensureProperties,
}: ActiveUserRequest): Promise<void> => {
  logger.debug(`Getting active user profile, version=${VERSION}`);
  const data = await activeUserProfile({
    userToken, // if not set, it will use the default one from interceptor
    businessType,
    marketId, // if not set, it will use the default one from AEM
    ensureProperties,
  });
  const loadedProfile = await loadProfile(
    { userToken, lastSelectedCompanyProfileId, marketId, ensureProperties },
    data?.responses[0].items,
  );

  if (loadedProfile) {
    loadedProfile.meta = { version: VERSION, status: 'READY' };

    logger.debug(`Now setting the status with ${JSON.stringify(loadedProfile)}`);
    await setStateToStore(loadedProfile);
  }
};

export const loadLastB2BState = async ({ ensureProperties }: { ensureProperties?: string[] } = {}): Promise<void> => {
  await loadLastState({
    userToken: '',
    businessType: CustomerBusinessType.B2B,
    marketId: '',
    ensureProperties,
  });
};

export const loadLastB2CState = async ({ ensureProperties }: { ensureProperties?: string[] } = {}): Promise<void> => {
  await loadLastState({
    userToken: '',
    businessType: CustomerBusinessType.B2C,
    marketId: '',
    ensureProperties,
  });
};

const forceCompany = async (
  userToken?: string,
  marketId?: string,
  ensureProperties?: string[],
  user?: User,
  company?: Company,
) => {
  // And reload (this time should be right)
  await saveStateToBackend({ ...initialState(), user, company });

  // And reload
  const data = await activeUserProfile({ userToken, marketId, ensureProperties });
  return { ...initialState(), ...data?.responses[0].items[0] };
};

const loadProfile = async (
  { userToken, lastSelectedCompanyProfileId, marketId, ensureProperties }: ActiveUserRequest,
  dataItems: Array<ActiveUserProfile> = [],
): Promise<B2XActiveProfileState> => {
  const loadedProfile: B2XActiveProfileState = await autoSelect(dataItems, userToken, marketId, ensureProperties);

  // set last company if required
  try {
    if (
      userToken &&
      loadedProfile.activeCustomerGroup?.customerType.businessType == B2B_BUSINESS_TYPE &&
      lastSelectedCompanyProfileId &&
      loadedProfile.company?.domainRef != lastSelectedCompanyProfileId
    ) {
      logger.debug(`Trying to select the previous company ${lastSelectedCompanyProfileId}`);
      // And reload (this time should be right)
      return forceCompany(userToken, marketId, ensureProperties, loadedProfile.user, {
        domainRef: { meId: lastSelectedCompanyProfileId },
      });
    }
  } catch (e) {
    logger.error('While checking current active', e);
  }

  return loadedProfile;
};

// Apply auto selection logic
const autoSelect = async (
  profiles: ActiveUserProfile[],
  userToken?: string,
  marketId?: string,
  ensureProperties?: string[],
): Promise<B2XActiveProfileState> => {
  type Y = {
    ANON: ActiveUserProfile[];
    REAL: ActiveUserProfile[];
  };
  type X = { B2B: Y; B2C: Y };

  const profileByCat = profiles.reduce<X>(
    (x, p) => {
      if (p.company) {
        if (p.company.domainRef) {
          x.B2B.REAL.push(p);
        } else {
          x.B2B.ANON.push(p);
        }
      } else {
        if (p.user?.domainRef) {
          x.B2C.REAL.push(p);
        } else {
          x.B2C.ANON.push(p);
        }
      }
      return x;
    },
    {
      B2B: { ANON: [], REAL: [] },
      B2C: { ANON: [], REAL: [] },
    } as X,
  );

  if (profileByCat.B2C.REAL.length > 0) {
    logger.debug('Selecting the B2C profile');
    return {
      ...initialState(),
      ...profileByCat.B2C.REAL[0],
    };
  }

  if (profileByCat.B2C.ANON.length > 0) {
    logger.debug('Selecting the B2C anonymous');
    return {
      ...initialState(),
      ...profileByCat.B2C.ANON[0],
    };
  }

  if (profileByCat.B2B.REAL.length == 1) {
    logger.debug('Selecting the unique B2B profile');
    return {
      ...initialState(),
      ...profileByCat.B2B.REAL[0],
    };
  }

  // If we only have the anonymous company we can still try to
  // get the company from the user
  if (profileByCat.B2B.ANON.length == 1 && profileByCat.B2B.ANON[0].user?.companies?.length == 1) {
    const user = profileByCat.B2B.ANON[0].user;
    const company = user?.companies && user.companies[0];

    return forceCompany(userToken, marketId, ensureProperties, user, company);
  }

  logger.debug('B2B mode without explicit company selection, reverting to default');
  return {
    ...initialState(),
    ...profileByCat.B2B.ANON[0],
  };
};
