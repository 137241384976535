import { CiasClaims, IdentityProvider, Logger, Stages, UserProfile, WKOService } from '@b2x/common';
import { Adapter } from '@b2x/oneweb-adapter';
import Cookies from 'js-cookie';

import {
  AUTH_LOGIN_ACTION,
  AUTH_LOGIN_ACTION_PENDING,
  AUTH_LOGOUT_ACTION,
  AUTH_PERSIST_STATE,
  AUTH_PROFILE_ACTION,
  AUTH_SET_ALERT_ACTION,
  AUTH_UPDATE_SSO_ACTION,
} from '../../common/actions';
import { ciasConfig, CONDITION_TYPE, COOKIE_NAME, psConfig, vsConfig } from '../../common/constants';
import { appendQueryParams, removeQueryParametersFromUrl } from '../../common/helpers';
import { getJwe } from '../../common/selectors';
import { Client, ClientConditionApi, LoginOptions, LogoutOptions } from '../../types/auth';
import { httpClient } from '../api';
import { Exception, InvalidLogin } from '../errors/exceptions';
import { Settings } from '../settings';

export class CiasAuthClient implements Client {
  public settings: Settings;

  protected logger = new Logger('CIAS_CLIENT');

  protected adapter!: Adapter;

  identityProvider = IdentityProvider.CIAS;

  constructor(env: Settings) {
    this.settings = env;
  }

  // TODO: prompt = 'DEFAULT'
  login(): void {
    const { redirectUrl } = this.settings.getItem('loginOptions') as LoginOptions;

    const initParams = {
      prompt: 'DEFAULT',
      locale: this.settings.locale,
      tenantId: this.settings.getItem('tenantId') || ciasConfig.tenantId,
      redirectUrl,
    };
    const stage = this.settings.getItem('stage') as Stages;
    const loginUrl = `${ciasConfig.envs[stage]}/authentication${appendQueryParams(initParams)}`;

    this.logger.debug('Login action on cias to url:', loginUrl);

    window.location.href = loginUrl;
  }

  logout(): void {
    const { targetUrl } = this.settings.getItem('logoutOptions') as LogoutOptions;
    const params = {
      locale: this.settings.locale,
      tenantId: this.settings.getItem('tenantId') || ciasConfig.tenantId,
      targetUrl,
    };
    const stage = this.settings.getItem('stage') as Stages;
    const logoutUrl = `${ciasConfig.envs[stage]}/logout${appendQueryParams(params)}`;

    this.logger.debug('CIAS logout redirecting to ', logoutUrl);

    window.location.href = logoutUrl;
  }

  async executeCondition(api: ClientConditionApi): Promise<void> {
    const { conditions, dispatch, getAction } = api;
    const { type, token, owcAemJwe, state, error, isPrivatePage } = conditions;
    const missingData = WKOService.getMissingData();

    this.logger.debug('Missing data status:', missingData);

    try {
      switch (type) {
        // Login from token(ott)
        case CONDITION_TYPE.AUTHENTICATING: {
          this.logger.debug('CIAS OTT is available', token);

          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, true));

          const cleanUrl = removeQueryParametersFromUrl(window.location.href, ['token']);
          window.history.replaceState(null, '', cleanUrl);

          const jwe = await this.fetchJwe(token as string).catch(() => {
            throw new InvalidLogin();
          });

          const claims = await this.fetchClaims(jwe).catch(() => {
            throw new InvalidLogin();
          });

          if (!missingData.length) {
            const profile = await this.fetchProfile(jwe);

            if (profile) {
              dispatch(getAction(AUTH_PROFILE_ACTION, profile));
            }
          }

          Cookies.set(COOKIE_NAME, 'ON');

          dispatch(getAction(AUTH_LOGIN_ACTION, { jwe, claims }));
          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, false));
          break;
        }

        // Coming from legacy loggedIn
        case CONDITION_TYPE.LEGACY: {
          this.logger.debug('OWC_AEM_JWE is available', owcAemJwe);

          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, true));

          await this.validateJwe(owcAemJwe as string)
            .then(async () => {
              const claims = await this.fetchClaims(owcAemJwe as string).catch(() => {
                throw new InvalidLogin();
              });

              dispatch(getAction(AUTH_LOGIN_ACTION, { jwe: owcAemJwe, claims }));

              if (!missingData.length) {
                const profile = await this.fetchProfile(owcAemJwe as string);

                if (profile) {
                  dispatch(getAction(AUTH_PROFILE_ACTION, profile));
                }
              }

              Cookies.set(COOKIE_NAME, 'ON');

              dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, false));
            })
            .catch(() => {
              dispatch(getAction(AUTH_LOGOUT_ACTION, IdentityProvider.CIAS));

              if (isPrivatePage) {
                this.login();
              }
            });

          break;
        }

        // User as persisted state
        case CONDITION_TYPE.AUTHENTICATED: {
          this.logger.debug('Auth present in storage, validate it');

          dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, true));

          const jwe = getJwe(state) as string;

          await this.validateJwe(jwe as string)
            .then(async () => {
              // don't keep fetching profile on every page if it's available in cache
              if (!missingData.length && !state.profile) {
                const profile = await this.fetchProfile(jwe);

                if (profile) {
                  dispatch(getAction(AUTH_PROFILE_ACTION, profile));
                }
              }

              dispatch(getAction(AUTH_LOGIN_ACTION_PENDING, false));
            })
            .catch(() => {
              dispatch(getAction(AUTH_LOGOUT_ACTION, IdentityProvider.CIAS));

              Cookies.remove(COOKIE_NAME);

              if (isPrivatePage) {
                this.login();
              }
            });

          break;
        }

        // Something went wrong during authenticating phase
        case CONDITION_TYPE.ERROR: {
          const cleanUrl = removeQueryParametersFromUrl(window.location.href, Object.keys(error));

          window.history.replaceState(null, '', cleanUrl);

          throw new InvalidLogin();
        }

        // SSO Attempt
        case CONDITION_TYPE.SSO: {
          dispatch(getAction(AUTH_UPDATE_SSO_ACTION, true));
          //dispatch(getAction(this.login.bind(this), 'NONE'));

          break;
        }

        // Defaults to Public condition
        default: {
          this.logger.debug('CIAS Anonymous case');

          dispatch(getAction(AUTH_PERSIST_STATE, conditions.state));

          if (isPrivatePage) {
            this.login();
          }
        }
      }
    } catch (error) {
      dispatch(getAction(AUTH_LOGOUT_ACTION, IdentityProvider.CIAS));

      const alert = error instanceof Exception ? error.toObject() : Exception.fromError(error as Error);

      dispatch(getAction(AUTH_SET_ALERT_ACTION, alert));

      if (isPrivatePage) {
        location.href = location.origin;
      }
    }

    // after all state changes are done, start listener in the adapter
    if (this.settings.isOneWeb) {
      this.adapter = new Adapter();
    }
  }

  private fetchJwe(ott: string): Promise<string> {
    const stage = this.settings.getItem('stage') as Stages;
    const baseUrl = ciasConfig.envs[stage] as string;

    return httpClient.fetchJwe(ott, baseUrl, IdentityProvider.CIAS);
  }

  private fetchClaims(jwe: string): Promise<CiasClaims> {
    const stage = this.settings.getItem('stage') as Stages;
    const baseUrl = vsConfig.envs[stage] as string;

    return httpClient.fetchClaims(jwe, baseUrl, IdentityProvider.CIAS);
  }

  private fetchProfile(jwe: string): Promise<UserProfile | null> {
    const stage = this.settings.getItem('stage') as Stages;
    const country = this.settings.getItem('country') as string;
    const baseUrl = psConfig.envs[stage] as string;

    return httpClient.fetchProfile(jwe, baseUrl, country).catch(() => {
      this.logger.warn('failed to fetch profile');
      return null;
    });
  }

  private validateJwe(jwe: string): Promise<CiasClaims> {
    const stage = this.settings.getItem('stage') as Stages;
    const baseUrl = vsConfig.envs[stage] as string;

    return httpClient.validateJwe(jwe, baseUrl, IdentityProvider.CIAS);
  }
}
