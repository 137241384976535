import { CompanyId } from '@dh-io-mhb2b/contracts-profiles';
import { FspRequestBuilder } from './fsp-request-builder';
import { apiInstancesHandler as apiHandler } from './api-service';

export const getSelectedCompany = async (loadingCompanyDomainRef: CompanyId | string | undefined): Promise<any> => {
  const { companyProfileApi } = await apiHandler.getInstances();
  const { data } = await companyProfileApi.getCompanies({
    requests: [
      {
        fspRequest: new FspRequestBuilder()
          .withEntityType('Company')
          .withBinaryFilterEq('domainRef', 'CompanyId', loadingCompanyDomainRef)
          .withIncludeDescriptor({
            includedPropertyPaths: [
              'accountVerificationState',
              'address.**',
              'businessType',
              'commercialRegistrationState',
              'countryCode',
              'displayCompanyId',
              'domainRef.**',
              'invoiceAddress.**',
              'isMandatoryConsentGiven',
              'name',
              'taxNumber',
              'validationRules.**',
            ],
          })
          .getInstance(),
      },
    ],
  });

  if (data && data.responses && data.responses.length) {
    if (data.responses[0].items && data.responses[0].items.length) {
      return data.responses[0].items[0];
    }
  }
  return {};
};
