import { Subscription } from '@seamless/store';
import { CMStoreData } from '../shared/model';

export const MODULE_NAME = 'cmStore';

export interface CMVuexStoreState extends CMStoreData {
  cmStoreSubscription: Subscription | null;
  namespace: string;
}

export type SubscriptionListener<T> = (state: T) => void;
