import { Alert, AlertType } from '@b2x/common';

import { DEFAULT_LOCALE, I18N_TRANSLATIONS } from '../../common/i18n';

export class Exception extends Error implements Alert {
  name = 'ERROR_GENERIC';

  type = AlertType.ERROR;

  static locale = DEFAULT_LOCALE;

  constructor(key: string) {
    super(key);

    this.name = key;
  }

  getMessage(): string {
    const locale = Exception.locale;
    const message = I18N_TRANSLATIONS[locale][this.name];

    return message ? message : I18N_TRANSLATIONS[DEFAULT_LOCALE]['ERROR_GENERIC'];
  }

  toObject(): Alert {
    return {
      message: this.getMessage(),
      type: this.type,
    };
  }

  static fromError(error: Error): Alert {
    return {
      message: (error as Error).message,
      type: AlertType.ERROR,
    };
  }
}

export class InvalidLogin extends Exception {
  constructor() {
    super('ERROR_LOGIN_GENERIC');
  }
}

export class InvalidLoginValidation extends Exception {
  constructor() {
    super('ERROR_LOGIN_VALIDATION_AUTHORIZATION');
  }
}

export class SessionExists extends Exception {
  type = AlertType.HIGHLIGHT;

  constructor() {
    super('ERROR_LOGIN_SESSION_EXISTS');
  }
}
