import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Subscription, initializeStore } from '@seamless/store';
import { logger, startApplication, MHB2BPhraseProject } from '@dh-io-mhb2b/fe-services';
import { defineCustomElements as defineCustomWebComponentLoaderElement } from '@owc/web-component-loader/loader';

import { waitForUserLogin } from '@commons-fe/mmcs-utils/loginState';
import { wkoStoreActions } from '@commons-fe/wko-vuex-module';
import { CMStatus, ValidationStatus, cmStoreActions } from '@dh-io-mmcs/consents-management-store/vuex';
import { CMSConnectionName } from '@dh-io-mmcs/consents-management-store/seamless';

import { loadTranslation, i18nService as i18n } from '@commons-fe/mmcs-utils/i18n';
import { AemEnvironmentHandler } from '@dh-io-mhb2b/aem-services';

import { storeNamespace } from '@/config/namespace';
import { buildInitConfig } from '@/config/initConfig';

import { B2XCoreConnection, WKOConnection } from '@/store/mock';
import CmwFooter from '@/components/footer/footer.vue';
import CmwHeader from '@/components/header/header.vue';

import store from './store';

const oneWebFile = require('../oneweb.js');
(async () => {
  defineCustomWebComponentLoaderElement(window);
  await startApplication({
    oneWebFile: process.env.NODE_ENV !== 'development' ? oneWebFile : null,
  });
})();

@Component({
  i18n,
  name: 'mh-cmw-dialog-wrapper',
  components: {
    CmwFooter,
    CmwHeader,
  },
})
export default class Mhb2bCmwDialogWrapper extends Vue {
  @Prop({ default: () => ({}), required: false, type: Object }) private readonly cmwConfigOverride!: Record<
    string,
    unknown
  >;
  @Prop({ default: false, required: false, type: Boolean }) private readonly hideCancel!: boolean;
  @Prop({ default: false, required: false, type: Boolean }) private readonly agreementIsOptional!: boolean;

  private subscription!: Subscription;
  private ns = storeNamespace;
  private scriptsLoaded = false;
  private canProceed = false;
  private requestActive = false;
  private stage = 'PPROD';
  private openDialog = false;
  private initConfig: any = {};

  async mounted(): Promise<void> {
    logger.log('CMW component mounted, cmStore.status:', this.getCMStatus);
    if (this.getCMStatus !== CMStatus.CONSENTS_LOADING_SUCCESS) {
      await this.initCMStore();
      await store.dispatch(wkoStoreActions.FETCH_WELL_KNOWN_OBJECTS);
      await waitForUserLogin();
      await this.loadAppTranslations();

      // TODO: ONLY ON LOCAL ENV
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
        await initializeStore().addConnection(new B2XCoreConnection());
        await initializeStore().addConnection(new WKOConnection());
      }
      this.initConfig = buildInitConfig(this.cmwConfigOverride || {});
      await store.dispatch(cmStoreActions.SET_CM_INITCONFIG, {
        config: this.initConfig,
        namespace: storeNamespace,
      });
      logger.log('Dispatched config, new CMStatus value:', this.getCMStatus);
    }
    this.openDialog = true;
  }

  get getCMStatus(): CMStatus {
    logger.log('========== getCMStatus change ===========', store?.state?.cmStore?.status);
    return store?.state?.cmStore?.status;
  }

  private async initCMStore() {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      const localStore = window?.seamlessStore?.store?.store?.getState
        ? // eslint-disable-next-line
        // @ts-ignore
          window.seamlessStore.store.store.getState()
        : {};
      if (localStore && localStore[CMSConnectionName] && localStore[CMSConnectionName][storeNamespace]) {
        logger.log('cmStore already exists, not reconnecting');
        return false;
      } else {
        logger.log('cmStore not detected, connecting...');
        await store.dispatch(cmStoreActions.CONNECT_TO_CM_STORE, storeNamespace);
        return true;
      }
    } catch (error) {
      logger.error('failed connecting to CM Store', error);
    }
  }

  private async loadAppTranslations() {
    try {
      const aemData = await AemEnvironmentHandler.getMarketAndLocale();
      await loadTranslation(aemData?.locale || 'de-DE');
      await (this as any).loadTranslations([
        MHB2BPhraseProject.PROFILE_OVERVIEW, // needed for cmc modal
      ]);
    } catch (error) {
      logger.error('Failed to load translations', error);
    }
  }

  async cancel() {
    await store.dispatch(cmStoreActions.SET_CM_STATUS, { status: CMStatus.WAITING_FOR_CONFIG });
    await store.dispatch(cmStoreActions.DISCONNECT_CM_STORE);
    logger.log('modal cancelled and dispatched store disconnection');
    this.openDialog = false;
    this.$emit('cancel');
  }

  async beforeDestroy(): Promise<void> {
    await store.dispatch(cmStoreActions.SET_CM_STATUS, { status: CMStatus.WAITING_FOR_CONFIG });
    await store.dispatch(cmStoreActions.DISCONNECT_CM_STORE);
    this.subscription?.unsubscribe();
    logger.log('component destroyed');
  }

  @Watch('getCMStatus')
  onCmStoreStatusChange(newVal: string) {
    logger.log('onCmStoreStatusChange', newVal);
    if (
      newVal === CMStatus.CONSENTS_SAVING_SUCCESS ||
      (this.agreementIsOptional && (newVal === ValidationStatus.INVALID || newVal === CMStatus.CONSENTS_SAVING_ABORTED))
    ) {
      this.$emit('saved');
      this.$nextTick(() => {
        this.openDialog = false;
      });
    }
  }
}
