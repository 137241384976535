import { CMSConnection } from './connection';
import { logger, seamlessStore } from '../shared/utils/seamless.utils';

const connectToCMStore = async (namespace: string): Promise<void> => {
  try {
    await seamlessStore.addConnection(new CMSConnection(namespace));
    return Promise.resolve();
  } catch (error) {
    logger.error('Error happened when connecting to consents management store', error);
    return Promise.reject(error);
  }
};

export { connectToCMStore, CMSConnection };
export { CMSConnectionName, CMStoreDispatchers, CMSeamlessStoreState } from './model';
