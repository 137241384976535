import { AuthenticationState, IdentityProvider, Logger } from '@b2x/common';
import { initializeStore, PersistentConnection, StorageTypeKeys } from '@seamless/store';
import Cookies from 'js-cookie';
import { AnyAction, Reducer } from 'redux';

import {
  AUTH_LOGIN_ACTION,
  AUTH_LOGIN_ACTION_PENDING,
  AUTH_LOGOUT_ACTION,
  AUTH_PERSIST_STATE,
  AUTH_PROFILE_ACTION,
  AUTH_REMOVE_ALERT_ACTION,
  AUTH_SET_ALERT_ACTION,
  AUTH_UPDATE_IDENTITY_PROVIDER,
} from '../common/actions';
import {
  AUTHENTICATION_CONNECTION,
  AUTHENTICATION_CONNECTION_LEGACY,
  CONDITION_TYPE,
  COOKIE_NAME,
  initialState,
} from '../common/constants';
import {
  getAlert,
  getClaims,
  getIdentityProvider,
  getJwe,
  getUserProfile,
  isLoggedIn,
  isPending,
} from '../common/selectors';
import { Exception, SessionExists } from '../services/errors/exceptions';
import { Settings } from '../services/settings';
// import { dispatchTracking } from '../services/tracking';
import {
  AsyncThunk,
  AuthenticationDispatchers,
  AuthenticationParams,
  AuthSelectors,
  Client,
  Conditions,
  LogoutOptions,
  RegisterOptions,
} from '../types/auth';
import { AuthConnectionBias } from './auth-connection-bias';
import { AuthConnectionCias } from './auth-connection-cias';
import { AuthConnectionLegacy, AuthDispatchersLegacy } from './auth-connection-legacy';

export class AuthenticationConnection extends PersistentConnection<
  AuthenticationDispatchers,
  AuthenticationState,
  AuthenticationState
> {
  logger = new Logger(AUTHENTICATION_CONNECTION);

  seamlessStore = initializeStore();

  private defaultState!: AuthenticationState;

  protected authConnectionLegacy = new AuthConnectionLegacy();

  /**
   * Clients cache instances
   */
  private clients = new Map<IdentityProvider, Client>();

  constructor(state: AuthenticationState = initialState) {
    super(AUTHENTICATION_CONNECTION);

    this.defaultState = state;

    this.seamlessStore.addConnection(this.authConnectionLegacy).catch(() => {
      this.logger.debug('Legacy connection already added');
    });

    this.seamlessStore.addConnection(new AuthConnectionCias()).catch(() => {
      this.logger.debug('AuthConnectionCIAS already added');
    });

    this.seamlessStore.addConnection(new AuthConnectionBias()).catch(() => {
      this.logger.debug('AuthConnectionCIAS already added');
    });
  }

  /**
   * Defines which type of persistence should be used to save auth state
   */
  get storageType(): StorageTypeKeys {
    return 'local';
  }

  /**
   * Gets the initial state value, also can be used to reset state to its initial condition
   */
  get initialState(): AuthenticationState {
    return Object.seal({ ...this.defaultState });
  }

  set initialState(authState: AuthenticationState) {
    this.defaultState = authState;
  }

  getPublicDispatchers(): AuthenticationDispatchers {
    return {
      login: this.loginAction.bind(this),
      logout: this.logoutAction.bind(this),
      init: this.initializeAction.bind(this),
      register: this.registerAction.bind(this),
      removeAlert: this.removeAlertAction.bind(this),
    };
  }

  public onStateChange(state: AuthenticationState): Promise<void> {
    this.logger.log('Authentication state changed', state);

    // to minimize amount of updates for legacy API, only publish when pending is finished
    if (!isPending(state)) {
      this.seamlessStore
        .getConnectionDispatchers<AuthDispatchersLegacy>(AUTHENTICATION_CONNECTION_LEGACY)
        .then((dispatchers) => {
          dispatchers.replaceState(state);
        });
    }

    return super.onStateChange(state);
  }

  /**
   * Public selectors. Selectors are functions to slice on
   * state and retrieves new value or object from there. Can also perform decisions or calculations without
   * mutating the state
   */
  getPublicSelectors(): AuthSelectors {
    return {
      isLoggedIn,
      getAlert,
      getClaims,
      getUserProfile,
      getJwe,
      getIdentityProvider,
      isPending,
    };
  }

  addClient(client: Client): void {
    this.clients.set(client.identityProvider, client);
  }

  /**
   * Maybe call the start engine. Here we define the api that auth clients can
   * interact and also call the execution of client condition method.
   */
  initializeAction(conditions: Conditions): AsyncThunk {
    return async (dispatch) => {
      const identityProvider = conditions.state.identityProvider;
      const client = this.clients.get(identityProvider);

      if (conditions.type === CONDITION_TYPE.AUTHENTICATED) {
        const sessionCookie = Cookies.get(COOKIE_NAME);
        this.logger.debug('Cookie is: ', sessionCookie);

        if (!sessionCookie) {
          const logoutOptions = {
            targetUrl: conditions.isPrivatePage ? location.origin : location.href,
          };

          return dispatch(this.logoutAction(logoutOptions));
        }
      }

      await client?.executeCondition({
        conditions,
        dispatch,
        getAction: this.getAction.bind(this),
        getActionType: this.getActionType.bind(this),
      });
    };
  }

  loginAction(options: AuthenticationParams): AsyncThunk {
    return async (dispatch, getState) => {
      try {
        const { B2XCORE_AUTHENTICATION: authState } = getState();
        const { identityProvider } = options;

        if (isLoggedIn(authState)) {
          this.logger.log('There is active session already, cannot start a new one');

          throw new SessionExists();
        }

        dispatch(this.getAction(AUTH_UPDATE_IDENTITY_PROVIDER, identityProvider));

        const client = this.clients.get(identityProvider) as Client;

        // dispatchTracking({
        //   category: 'feature',
        //   type: 'AL',
        //   label: 'login',
        //   event: 'action',
        //   action: identityProvider,
        // });

        client.settings = new Settings({ ...client.settings.getItems(), ...options });

        dispatch(() => client.login());
      } catch (error) {
        const alert = error instanceof Exception ? error.toObject() : Exception.fromError(error as Error);

        dispatch(this.getAction(AUTH_SET_ALERT_ACTION, alert));
      }
    };
  }

  logoutAction(options?: LogoutOptions): AsyncThunk {
    return async (dispatch, getState) => {
      const { B2XCORE_AUTHENTICATION: authState } = getState();

      const client = this.clients.get(authState.identityProvider) as Client;

      const logoutOptions: LogoutOptions = {
        ...(client.settings.getItem('logoutOptions') as LogoutOptions),
        ...options,
      };

      // dispatchTracking({
      //   category: 'feature',
      //   type: 'AL',
      //   label: 'logout',
      //   event: 'action',
      //   action: authState.identityProvider,
      // });

      client.settings = new Settings({ ...client.settings.getItems(), ...{ logoutOptions } });

      Cookies.remove(COOKIE_NAME);

      dispatch(this.getAction(AUTH_LOGOUT_ACTION, authState.identityProvider));
      dispatch(() => client.logout());
    };
  }

  registerAction(options: RegisterOptions): AsyncThunk {
    // First interaction to send to legacy servlets. Martin and hi
    // agree that we need to dig the old code to check if we can use cias
    // for the next iteration on this
    return async (dispatch, getState) => {
      const { B2XCORE_AUTHENTICATION: authState } = getState();

      const client = this.clients.get(authState.identityProvider) as Client;

      const locale = client.settings.locale;

      const defaultRedirect = `${window.location.origin}/bin/daimler/public/ciam/registration.html`;
      const { redirectUrl = defaultRedirect } = options || ({} as RegisterOptions);

      dispatch(() => {
        window.location.href = `${redirectUrl}?lang=${locale}`;
      });
    };
  }

  removeAlertAction(): AsyncThunk {
    return async (dispatch) => {
      dispatch(this.getAction(AUTH_REMOVE_ALERT_ACTION));
    };
  }

  getReducer(): Reducer {
    return (state: AuthenticationState, action: AnyAction): AuthenticationState => {
      this.logger.debug(`Reducer triggered on action: '${action.type}' with payload:`, action.payload);

      switch (action.type) {
        case this.getActionType(AUTH_PERSIST_STATE):
          return { ...state, ...action.payload };

        case this.getActionType(AUTH_UPDATE_IDENTITY_PROVIDER):
          return { ...state, identityProvider: action.payload };

        case this.getActionType(AUTH_LOGIN_ACTION_PENDING):
          return { ...state, isPending: action.payload };

        case this.getActionType(AUTH_LOGIN_ACTION):
          return { ...state, ...action.payload };

        case this.getActionType(AUTH_PROFILE_ACTION):
          return { ...state, profile: action.payload };

        case this.getActionType(AUTH_LOGOUT_ACTION):
          return {
            ...initialState,
            identityProvider: action.payload,
          };

        case this.getActionType(AUTH_SET_ALERT_ACTION):
          return { ...state, alert: action.payload };

        case this.getActionType(AUTH_REMOVE_ALERT_ACTION):
          return { ...state, alert: null };

        /*
        case this.getActionType(AUTH_UPDATE_SSO_ACTION): {
          return { ...state, sso: action.payload };
        }
        */
        default:
          return state;
      }
    };
  }

  loadPersistedStateCallback(persistedState: AuthenticationState): AnyAction {
    const cleanState = Object.assign(Object.create(null), JSON.parse(JSON.stringify(persistedState)));

    return this.getAction(AUTH_PERSIST_STATE, { ...cleanState });
  }

  async transformToPersistentState(state: AuthenticationState): Promise<AuthenticationState> {
    const cleanState = Object.assign(Object.create(null), JSON.parse(JSON.stringify(state)));

    return cleanState;
  }
}
