import { TrackingEvent, getTrackingReducerConnection } from '@owc/connection-aem-tracking';
let connection: any = null;

export const APP_VERSION = '1.0',
  APP_ID = 'mehome.b2b',
  APP_BUSINESS_UNIT = 'b2b';

export interface TrackingEventPage {
  name: string;
  type: string;
  vehicle?: {
    baumusterId: string;
    model: string;
  };
}
export default {
  async getTrackingConnection(): Promise<any> {
    if (!connection) {
      connection = await getTrackingReducerConnection();
    }
    return connection;
  },

  async pushEvent(event: TrackingEvent): Promise<void> {
    const trackingConnection = await this.getTrackingConnection();
    trackingConnection.pushTrackingEvent(event);
  },

  async pushClickEvent(value: any): Promise<void> {
    const event = {
      event: 'click',
      application: {
        id: APP_ID,
        businessUnit: APP_BUSINESS_UNIT,
        version: APP_VERSION,
      },
      ...value,
    };
    this.pushEvent(event);
  },

  async pushPageViewEvent(page: TrackingEventPage): Promise<void> {
    const event = {
      event: 'pageview',
      page,
      user: {
        type: 'business',
      },
      application: {
        id: APP_ID,
        businessUnit: APP_BUSINESS_UNIT,
        version: APP_VERSION,
      },
    };
    this.pushEvent(event);
  },
};
