import { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

/**
 * We use X-SessionId for our logging. Each request has the same sessionId per application
 */
const sessionId = uuidv4();
export function sessionIdInterceptor(config: AxiosRequestConfig<any>): AxiosRequestConfig<any> {
  const headers = {
    ...config.headers,
    'X-SessionId': sessionId,
  };

  return {
    ...config,
    headers,
  };
}
