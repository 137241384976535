import { getJweData } from './get-jwe-data';
import { AxiosRequestConfig } from 'axios';

/**
 * We use bearer as a identification token
 * Sometimes you don't want to send your token to some endpoints. That's why we can have an optional paramter
 * for that. For example the MMCS Team must not send the token to the assets/i18n endpoint.
 */
export function getBearerTokenInterceptor(
  excludeUrls?: string[],
): (config: AxiosRequestConfig<any>) => Promise<AxiosRequestConfig<any>> {
  return async (config: AxiosRequestConfig<any>) => {
    if (excludeUrls && excludeUrls.some((url) => config.url?.includes(url))) {
      return config;
    }

    const token = await getJweData();

    const headers = token
      ? {
          ...config.headers,
          'authorization-jwe': `Bearer ${token}`,
        }
      : { ...config.headers };

    return { ...config, headers };
  };
}
