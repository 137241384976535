import { buildPaths } from '@commons-fe/mmcs-utils/vuex';

export const MODULE_NAME = 'wellKnownObjects';

export const ACTIONS = {
  FETCH_WELL_KNOWN_OBJECTS: 'FETCH_WELL_KNOWN_OBJECTS',
  SUBSCRIBE_TO_WELL_KNOWN_OBJECTS: 'SUBSCRIBE_TO_WELL_KNOWN_OBJECTS',
};

export const MUTATIONS = {
  SET_STATUS: 'SET_STATUS',
  SET_WKO_VALUES: 'SET_WKO_VALUES',
};

export const GETTERS = {
  GET_LOCALE: 'GET_LOCALE',
};

export const MUTATION_PATHS = buildPaths(MODULE_NAME, MUTATIONS);
export const ACTION_PATHS = buildPaths(MODULE_NAME, ACTIONS);
export const GETTER_PATHS = buildPaths(MODULE_NAME, GETTERS);
