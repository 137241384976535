import { SeamlessConnection } from '@seamless/store';
import { TrackingEvent, TrackingReducerState, OwcTrackingDispatchers } from './types';

// the reducer connection name please use getTrackingReducerConnection() if you need the connection
export const connectionName = 'OWC_TRACKING_MANAGER';

// Action const
export const PUSH_TRACKING_EVENT = 'PUSH_TRACKING_EVENT';

const initialTrackingState: TrackingReducerState = {
  trackingEvents: [],
};

class trackingConnection extends SeamlessConnection<OwcTrackingDispatchers, TrackingReducerState> {
  constructor() {
    super(connectionName);
  }

  get initialState() {
    return initialTrackingState;
  }

  getReducer() {
    return (state = initialTrackingState, action: any) => {
      switch (action.type) {
        case this.getActionType(PUSH_TRACKING_EVENT):
          return {
            ...state,
            trackingEvents: [...state.trackingEvents, action.payload],
          };
        default:
          return state;
      }
    };
  }

  getPublicDispatchers() {
    return {
      pushTrackingEvent: (trackingEvent: TrackingEvent) => {
        window.top?.dataLayer_ow.push(trackingEvent);
        return this.getAction(PUSH_TRACKING_EVENT, trackingEvent);
      },
    };
  }
}

export const connection = new trackingConnection();
