import { Subscription } from '@seamless/store';
import { CMStatus, InitConfig, ValidationResult } from '../shared/model';
import { CMStoreDispatchers, CMSeamlessStoreState, CMSConnectionName } from '../seamless';
import { seamlessStore } from '../shared/utils/seamless.utils';

const cmcDispatchersPromise: Promise<CMStoreDispatchers> = seamlessStore.getConnectionDispatchers(CMSConnectionName);

export function startCMCStoreSubscription(listener: (state: CMSeamlessStoreState) => void): Subscription {
  return seamlessStore.subscribe(CMSConnectionName, listener);
}

export function stopCMCStoreSubscription(cmcSubscription: Subscription | null): void {
  if (cmcSubscription) {
    cmcSubscription.unsubscribe();
  }
}

export async function changeCMStoreStatus(status: CMStatus, namespace: string): Promise<void> {
  (await cmcDispatchersPromise).updateStatus(status, namespace);
}

export async function changeCMStoreTrackingId(trackingId: string, namespace: string): Promise<void> {
  (await cmcDispatchersPromise).updateTrackingId(trackingId, namespace);
}

export async function changeCMStoreInitConfig(config: InitConfig, namespace: string): Promise<void> {
  (await cmcDispatchersPromise).updateConfig(config, namespace);
}

export async function changeCMStoreOldDocumentVersionAgreed(
  oldDocumentVersionAgreed: boolean,
  namespace: string,
): Promise<void> {
  (await cmcDispatchersPromise).updateOldDocumentVersionAgreed(oldDocumentVersionAgreed, namespace);
}

export async function changeCMStoreConsentCount(consentCount: number, namespace: string): Promise<void> {
  (await cmcDispatchersPromise).updateConsentCount(consentCount, namespace);
}

export async function changeCMStoreValidationResult(
  validationResult: ValidationResult,
  namespace: string,
): Promise<void> {
  (await cmcDispatchersPromise).updateValidationResult(validationResult, namespace);
}
