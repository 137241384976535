import { CompanyId, ActivePermissions } from '@dh-io-mhb2b/b2x-permissions';
import { FspRequestBuilder } from './fsp-request-builder';
import { apiHandler } from '../api/api-service';

class PermissionServiceClass {
  async loadPermissions(companyDomainRef: CompanyId): Promise<ActivePermissions> {
    const { permissionsApi } = await apiHandler.getInstances('MHB2B');
    const response = await permissionsApi.getCurrentUserPermissions({
      requests: [
        {
          fspRequest: new FspRequestBuilder()
            .withEntityType('PermissionsOfUserWithinContext')
            .withBinaryFilterEq('company.domainRef', 'CompanyId', companyDomainRef)
            .getInstance(),
        },
      ],
    });
    return (response.data as any).responses[0].items[0] as ActivePermissions;
  }
}

export const PermissionService = new PermissionServiceClass();
