const COUNTRIES_ECE = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IN',
  'IE',
  'IT',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SK',
  'SI',
  'ZA',
  'ES',
  'SE',
  'CH',
  'TH',
  'TR',
  'GB',
  'AQ',

  // Note: XA and XD provided by OneWeb will be mapped to AE-AZ
  // and AE-DU internally before calling the toRegion function.
  // The mapping is required for our backends to work. For the
  // region mapping we could just use XA and XD directly, but
  // using the AE values across the whole application is more
  // consistent and less confusing.
  'AE-AZ',
  'AE-DU',
];

const COUNTRIES_AMAP = ['AU', 'BR', 'CA', 'JP', 'MY', 'MX', 'NZ', 'KR', 'SG', 'TW', 'US'];

const COUNTRIES_CN = ['CN', 'HK', 'MO'];

function toRegion(country: string): 'ECE' | 'AMAP' | 'CN' {
  if (COUNTRIES_ECE.includes(country)) {
    return 'ECE';
  } else if (COUNTRIES_AMAP.includes(country)) {
    return 'AMAP';
  } else if (COUNTRIES_CN.includes(country)) {
    return 'CN';
  } else {
    throw new Error(`Country ${country} is not supported`);
  }
}

function convertStage(stage: string): 'INT' | 'PPROD' | 'PROD' {
  if (stage === 'PROD') {
    return 'PROD';
  }
  if (stage === 'PPROD' || stage === 'PREPROD') {
    return 'PPROD';
  }
  // All the rest --> int, local, dev, test, mock, ...
  return 'INT';
}

export const environment: any = {
  ECE: {
    INT: {
      serverApiUrl: 'https://services.me-int.mercedes-benz.com/mhb2b-int',
    },
    PPROD: {
      serverApiUrl: 'https://services.me-int.mercedes-benz.com/mhb2b-pprod',
    },
    PROD: {
      serverApiUrl: 'https://services.me.mercedes-benz.com/mhb2b-prod',
    },
  },
  AMAP: {
    INT: {
      serverApiUrl: 'https://services-amap.me-int.mercedes-benz.com/mhb2b-int',
    },
    PPROD: {
      serverApiUrl: 'https://services-amap.me-int.mercedes-benz.com/mhb2b-pprod',
    },
    PROD: {
      serverApiUrl: 'https://services-amap.me.mercedes-benz.com/mhb2b-prod',
    },
  },
  CN: {
    INT: {
      serverApiUrl: 'https://services-china.me-int.mercedes-benz.com/mhb2b-int',
    },
    PPROD: {
      serverApiUrl: 'https://services-china.me-int.mercedes-benz.com/mhb2b-pprod',
    },
    PROD: {
      serverApiUrl: 'https://services.me-prod.mercedes-benz.com.cn/mhb2b-prod',
    },
  },
};

export function getApiBaseUrl(country: string, stage: string): any {
  const region = toRegion(country);
  const convertedState = convertStage(stage);
  return environment[region][convertedState];
}
