import { AnyAction, Reducer } from 'redux';
import dotProp from 'dot-prop';
import { SeamlessCoreStore, initializeStore, PersistentConnection, StorageTypeKeys } from '@seamless/store';
import { initializeLogger } from '@seamless/logger';
import {
  Market,
  UserLocale,
  Company,
  User,
  Fleet,
  Vehicle,
  AppliedRole,
  UserJourneyCache,
  ActionPermission,
  CustomerGroup,
} from '@dh-io-mhb2b/api-model';

import { B2X_ACTIVE_PROFILE_CONNECTION_NAME } from './constants';
import { ActionTypes } from './actions';
import { B2XActiveUserDispatchers, PersistOpts } from './dispatchers';
import { initialState, B2XActiveProfileState, B2XActiveProfilePersistedState } from './state';
import { saveStateToBackend } from './utils/utils-persist-backend-save';
import { loadPersistentStateFromSessionStorage, savePersistentStateToSessionStorage } from './utils/utils-persist-session-storage';
import {
  loadLastState,
  loadLastB2CState,
  loadLastB2BState,
  ActiveUserRequest,
} from './utils/utils-persist-backend-load';
import { generateNewUserJourneyCache } from './utils/utils-cache';
import { PermissionService } from '../services/permission-service';
import { saveStateToUCMS } from '../services/ucms-service';

declare const VERSION: string;

const logger = initializeLogger('B2X-PAL');

export class B2XActiveUserConnection extends PersistentConnection<
  B2XActiveUserDispatchers,
  B2XActiveProfileState | null,
  B2XActiveProfilePersistedState | null
> {
  constructor() {
    super(B2X_ACTIVE_PROFILE_CONNECTION_NAME);
  }

  get initialState(): B2XActiveProfileState | null {
    logger.log(`Connection initialized with version: ${VERSION}`);
    return initialState();
  }

  getReducer(): Reducer {
    return (state: B2XActiveProfileState, action: AnyAction): B2XActiveProfileState => {
      switch (action.type) {
        case this.getActionType(ActionTypes.SET_MARKET):
          return {
            ...state,
            market: action.payload,
          };
        case this.getActionType(ActionTypes.SET_LOCALE):
          return {
            ...state,
            locale: action.payload,
          };
        case this.getActionType(ActionTypes.SET_COMPANY):
          return {
            ...state,
            company: action.payload,
            roles: [],
            fleets: [],
            vehicles: [],
            permissions: {},
            userJourneyCache: generateNewUserJourneyCache(),
            ensureProperties: [],
          };
        case this.getActionType(ActionTypes.UPDATE_COMPANY):
          return {
            ...state,
            company: action.payload,
          };
        case this.getActionType(ActionTypes.SET_USER):
          return {
            ...state,
            user: action.payload,
          };
        case this.getActionType(ActionTypes.SET_FLEETS):
          return {
            ...state,
            fleets: action.payload,
          };
        case this.getActionType(ActionTypes.SET_VEHICLES):
          return {
            ...state,
            vehicles: action.payload,
          };
        case this.getActionType(ActionTypes.SET_VEHICLE_COUNT):
          return {
            ...state,
            company: {
              ...state.company,
              vehicleCount: action.payload,
            },
          };
        case this.getActionType(ActionTypes.SET_ROLES):
          return {
            ...state,
            roles: action.payload,
          };
        case this.getActionType(ActionTypes.SET_USER_JOURNEY_CACHE):
          return {
            ...state,
            userJourneyCache: action.payload,
          };
        case this.getActionType(ActionTypes.REGENERATE_USER_JOURNEY_CACHE):
          return {
            ...state,
            userJourneyCache: generateNewUserJourneyCache(),
            ensureProperties: [],
          };
        case this.getActionType(ActionTypes.SET_ACTION_PERMISSIONS):
          return {
            ...state,
            permissions: {
              ...state.permissions,
              actionPermissions: action.payload,
            },
          };
        case this.getActionType(ActionTypes.SET_SERVICE_PERMISSIONS):
          return {
            ...state,
            permissions: {
              ...state.permissions,
              servicePermissions: action.payload,
            },
          };
        case this.getActionType(ActionTypes.SET_ENTITY_PERMISSIONS):
          return {
            ...state,
            permissions: {
              ...state.permissions,
              entityPermissions: action.payload,
            },
          };
        case this.getActionType(ActionTypes.SET_ACTIVE_COSTUMER_GROUP):
          return {
            ...state,
            activeCustomerGroup: action.payload,
          };
        case this.getActionType(ActionTypes.ENSURE_PROPERTIES):
          return {
            ...state,
            ensureProperties: [...new Set([...(state.ensureProperties || []), ...action.payload])],
          };
        case this.getActionType(ActionTypes.SET_STATE):
          return {
            ...state,
            ...action.payload,
          };
        case this.getActionType(ActionTypes.RESET_STATE):
          return { ...state };
        default:
          return state;
      }
    };
  }

  getPublicDispatchers(): B2XActiveUserDispatchers {
    return {
      setMarket: (payload: Market) => this.getAction(ActionTypes.SET_MARKET, payload),
      setLocale: (payload: UserLocale) => this.getAction(ActionTypes.SET_LOCALE, payload),
      setCompany:
        (
          company: Company | undefined,
          roles: Array<AppliedRole> = [],
          actionPermissions: Array<ActionPermission> = [],
          persistBE: PersistOpts = true,
          callback?: any,
        ) =>
        async (dispatch: any, getState: any): Promise<void> => {
          if (company && company.domainRef) {
            dispatch(this.getAction(ActionTypes.SET_COMPANY, company));
            dispatch(this.getAction(ActionTypes.SET_ROLES, roles));

            if (actionPermissions?.length) {
              dispatch(this.getAction(ActionTypes.SET_ACTION_PERMISSIONS, actionPermissions));
            } else {
              try {
                const permissions = await PermissionService.loadPermissions(company.domainRef);
                dispatch(this.getAction(ActionTypes.SET_ACTION_PERMISSIONS, permissions.actionPermissions));
              } catch (err) {
                logger.error('Could not load permissions ... ', err);
              }
            }
          } else {
            dispatch(this.getAction(ActionTypes.SET_COMPANY, company));
          }

          await this.postDispatcherCall(persistBE, getState, callback);
        },
      setUser:
        (payload: User, persistBE = true, callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          dispatch(this.getAction(ActionTypes.SET_USER, payload));
          await this.postDispatcherCall(persistBE, getState, callback);
        },
      setFleets:
        (payload: Array<Fleet>, persistBE = true, callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          dispatch(this.getAction(ActionTypes.SET_FLEETS, payload));
          await this.postDispatcherCall(persistBE, getState, callback);
        },
      setVehicles:
        (payload: Array<Vehicle>, persistBE = true, callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          dispatch(this.getAction(ActionTypes.SET_VEHICLES, payload));
          await this.postDispatcherCall(persistBE, getState, callback);
        },
      setVehicleCount: (payload: number) => this.getAction(ActionTypes.SET_VEHICLE_COUNT, payload),
      setUserJourneyCache: (payload: UserJourneyCache) => this.getAction(ActionTypes.SET_USER_JOURNEY_CACHE, payload),
      regenerateUserJourneyCache: () => this.getAction(ActionTypes.REGENERATE_USER_JOURNEY_CACHE),
      setActiveCustomerGroup: (payload: CustomerGroup) =>
        this.getAction(ActionTypes.SET_ACTIVE_COSTUMER_GROUP, payload),
      resetState: () => this.getAction(ActionTypes.RESET_STATE),
      setState: (state: B2XActiveProfileState) => this.getAction(ActionTypes.SET_STATE, state),
      loadLastStateFromBE:
        (payload: ActiveUserRequest, callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          await loadLastState({
            ensureProperties: (getState()[B2X_ACTIVE_PROFILE_CONNECTION_NAME] as B2XActiveProfileState)
              ?.ensureProperties,
            ...payload,
          });
          await this.postDispatcherCall(false, getState, callback);
        },
      loadLastB2CStateFromBE:
        (callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          await loadLastB2CState({
            ensureProperties: (getState()[B2X_ACTIVE_PROFILE_CONNECTION_NAME] as B2XActiveProfileState)
              ?.ensureProperties,
          });
          await this.postDispatcherCall(false, getState, callback);
        },
      loadLastB2BStateFromBE:
        (callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          await loadLastB2BState({
            ensureProperties: (getState()[B2X_ACTIVE_PROFILE_CONNECTION_NAME] as B2XActiveProfileState)
              ?.ensureProperties,
          });
          await this.postDispatcherCall(false, getState, callback);
        },
      ensureProperties:
        (payload: Array<string>, callback?: any) =>
        async (dispatch: any, getState: any): Promise<void> => {
          dispatch(this.getAction(ActionTypes.ENSURE_PROPERTIES, payload));
          const currentState: B2XActiveProfileState = getState()[
            B2X_ACTIVE_PROFILE_CONNECTION_NAME
          ] as B2XActiveProfileState;

          const propertiesToFetch: Array<string> = [];
          (currentState.ensureProperties || []).forEach((property: string) => {
            const propValue = dotProp.get(currentState, property.replaceAll('.*', '').replaceAll('.**', ''));
            if (propValue === null || propValue === undefined) {
              propertiesToFetch.push(property);
            }
          });

          if (propertiesToFetch.length) {
            await loadLastState({ ensureProperties: payload });
          }
          await this.postDispatcherCall(false, getState, callback);
        },
    };
  }

  // Persistent state -- still in discussion how to improve
  get storageType(): StorageTypeKeys {
    return 'session';
  }

  loadPersistedStateCallback(persistedState: B2XActiveProfilePersistedState): AnyAction {
    return this.getAction(ActionTypes.SET_STATE, loadPersistentStateFromSessionStorage({
      ...persistedState,
      meta: {
        ...persistedState.meta,
        version: VERSION,
        status: 'INITIALIZING',
      },
    }));
  }

  transformToPersistentState(state: B2XActiveProfileState): B2XActiveProfilePersistedState {
    return savePersistentStateToSessionStorage(state);
  }
  // -------

  // Private auxiliary functions
  private async postDispatcherCall(opts: PersistOpts, getState: any, callback?: any): Promise<void> {
    const { persistBE, persistUCMS } =
      opts === true ? { persistBE: true, persistUCMS: true } : !opts ? { persistBE: false, persistUCMS: false } : opts;

    logger.debug(`post dispatcher, persistBE=${persistBE}, persistUCMS=${persistUCMS} / opts=${JSON.stringify(opts)}`);

    const newState = getState()[B2X_ACTIVE_PROFILE_CONNECTION_NAME] as B2XActiveProfileState;

    const jobs = [] as Promise<any>[];
    if (persistUCMS) {
      // Save state to both backends
      jobs.push(saveStateToUCMS(newState));
    }
    if (persistBE) {
      jobs.push(saveStateToBackend(newState));
    }
    if (jobs.length) {
      await Promise.all(jobs);
    }

    if (callback && typeof callback === 'function') {
      callback(newState);
    }
  }
}

export const setupActiveProfileConnection = (store?: SeamlessCoreStore): Promise<void> => {
  return (store || initializeStore()).addConnection(new B2XActiveUserConnection());
};
