import { initializeStore } from '@seamless/store';
import {
  addConnectionAemEnvironmentConnection,
  ConnectionAemEnvironmentState,
  connectionName,
} from '@owc/connection-aem-environment';

export async function getAemEnvironmentPromise(): Promise<ConnectionAemEnvironmentState> {
  const store = initializeStore();
  try {
    await addConnectionAemEnvironmentConnection();
  } catch (_error) {
    // Do nothing
  }

  return await new Promise((resolve) => {
    store.once(connectionName, (state: ConnectionAemEnvironmentState) => {
      resolve(state);
    });
  });
}

const aemEnvironmentPromise = getAemEnvironmentPromise();

export const aemEnvironmentHandler = {
  getAemEnvironment(): Promise<ConnectionAemEnvironmentState> {
    return aemEnvironmentPromise;
  },

  async getCountry(): Promise<string> {
    const aemEnvironment = await aemEnvironmentPromise;
    return aemEnvironment.country;
  },

  async getLanguage(): Promise<string> {
    const aemEnvironment = await aemEnvironmentPromise;
    return aemEnvironment.language;
  },

  async getStage(): Promise<string> {
    const aemEnvironment = await aemEnvironmentPromise;
    return aemEnvironment.stage;
  },

  async getRunMode(): Promise<string> {
    const aemEnvironment = await aemEnvironmentPromise;
    return aemEnvironment.runMode;
  },
};
