export const AUTH_PERSIST_STATE = 'AUTH_PERSIST_STATE';

export const AUTH_LOGIN_ACTION = 'AUTH_LOGIN_ACTION';

export const AUTH_LOGIN_ACTION_PENDING = 'AUTH_LOGIN_ACTION_PENDING';

export const AUTH_LOGOUT_ACTION = 'AUTH_LOGOUT_ACTION';

export const AUTH_PROFILE_ACTION = 'AUTH_PROFILE_ACTION';

export const AUTH_UPDATE_IDENTITY_PROVIDER = 'AUTH_UPDATE_IDENTITY_PROVIDER';

export const AUTH_UPDATE_SSO_ACTION = 'AUTH_UPDATE_SSO_ACTION';

export const AUTH_SET_ALERT_ACTION = 'AUTH_SET_ALERT_ACTION';

export const AUTH_REMOVE_ALERT_ACTION = 'AUTH_REMOVE_ALERT_ACTION';
