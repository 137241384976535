import VueI18n from 'vue-i18n';
import { getTranslation, getLocale } from '@owc/connection-aem-translations';

export enum MHB2BPhraseProject {
  PROFILE_OVERVIEW = '8051573e860ea49fc5625f65fb0133bd',
  ADD_FORM = '97f0bab7e64fb1cc4fc191804e824049',
  VERIFY_FORM = 'a592b73e0e854b1619bd4d85732768f0',
  COMPANY_DETAILS = '02caf90e1d619ac18a4b3aa6411b990e',
  COMPANY_USER_MANAGEMENT = '7ff23fd8fcfcfb0c9d9fdc29b7c44ea3',
  COMPANY_DASHBOARD = '5a6579ae27aada5761b5e95ee5e2b50d',
  VEHICLE = '3e49a12ba17aeb426913d232e95beabb',
  FORMS = '9f8dba6ee176f3d8ac2856930d8369e3',
  SHARED = '2e8ca0e0336521e57ed4187d179fed09',
  ROLES = 'dc03220165d270615eef46e51cb2b3f4',
  BREADCRUMBS = '32175206855633857cd843bd8d956999',
  DMO_FINANCE = 'dfb41970555e48482fa897028f816bcf',
  DMO_CONSENT = '2af8d82835bcbbd153c95ca32b9446be',
}

export const I18nPlugin = {
  install(Vue: any): void {
    const vueI18n = new VueI18n({
      locale: 'en-GB',
      fallbackLocale: 'en-GB',
    });

    Vue.mixin({
      methods: {
        async loadTranslations(projectList: Array<MHB2BPhraseProject | string>, locale = ''): Promise<void> {
          const loadedLocale = locale || (await getLocale());
          vueI18n.locale = loadedLocale;
          for (const project of projectList) {
            const messages = await getTranslation(project, loadedLocale);
            vueI18n.mergeLocaleMessage(loadedLocale, messages);
          }
        },
        $mhTranslate(key: string, params: VueI18n.Values | undefined = {}): string {
          return vueI18n.t(key, params) as string;
        },
        $mhTranslateCount(key: string, count: number, params: VueI18n.Values | undefined = {}): string {
          return vueI18n.tc(key, count, params) as string;
        },
      },
    });
  },
};
