import { getTrackingDispatchers, TrackingEvent } from '@owc/connection-aem-tracking';

import { isAemEnvironment } from '../common/helpers';
import { version } from '../common/version';

type TrackEvent = {
  event?: string;
  category?: string;
  label: string;
  type: string;
  action?: string;
  url?: string;
};

export const dispatchTracking = async (trackEvent: TrackEvent): Promise<void> => {
  const isAem = await isAemEnvironment();

  const {
    action = 'header.user_area',
    event = 'click',
    category = 'link',
    label = 'default',
    type = 'AL',
  } = trackEvent;

  const labelToUrlMap: Record<string, string> = {
    login: '/authentication',
    logout: '/logout',
    register: '/registration',
  };

  const url = labelToUrlMap[label] || '';

  const trackingEvent: TrackingEvent = {
    event,
    eventDetail: {
      category,
      action,
      label,
      url,
    },
    application: {
      id: 'B2X',
      version: `${type}-${version}`,
      parentApplicationId: isAem ? 'aem' : 'emh',
    },
  };

  if (isAem) {
    return getTrackingDispatchers().then((dispatchers) => {
      dispatchers.pushTrackingEvent(trackingEvent);
    });
  } else {
    return new Promise((resolve) => {
      window.dataLayer_ow?.push(trackingEvent);
      resolve();
    });
  }
};
