/* istanbul ignore file */
import store from './index';
import { WKOState, wkoStoreModuleName } from '@commons-fe/wko-vuex-module';
import { CMVuexStoreState, cmStoreModuleName } from '@dh-io-mmcs/consents-management-store/vuex';
import { AEMState, aemStoreModuleName } from '@commons-fe/aem-vuex-module';

export type Immutable<T> = {
  readonly [K in keyof T]: Immutable<T[K]>;
};
export default abstract class TOUCState {
  public static get wkoStore(): WKOState {
    return store.state[wkoStoreModuleName];
  }
  public static get cmStore(): CMVuexStoreState {
    return store.state[cmStoreModuleName];
  }
  public static get aemStore(): AEMState {
    return store.state[aemStoreModuleName];
  }
}
