import { CiasClaims, IdentityProvider, Logger, UserProfile } from '@b2x/common';
import ky from 'ky';

import { biasConfig, ciasConfig } from '../common/constants';

export class HttpApi {
  private http = ky.create({ prefixUrl: '' });

  protected logger = new Logger('HTTP_CLIENT');

  async fetchJwe(ott: string, baseUrl: string, authorithy: string): Promise<string> {
    return authorithy.toLowerCase() === 'cias' ? this.fetchCiasJwe(ott, baseUrl) : this.fetchBiasJwe(ott, baseUrl);
  }

  async fetchProfile(jwe: string, baseUrl: string, countryCode: string): Promise<UserProfile> {
    this.logger.debug('fetch user profile');

    return this.http
      .get('profile', {
        headers: {
          Authorization: `Bearer ${jwe}`,
        },
        searchParams: {
          country_code: countryCode,
        },
        prefixUrl: baseUrl,
      })
      .json<UserProfile>()
      .then((userProfile) => {
        this.logger.debug('Got user profile', userProfile);
        return userProfile;
      });
  }

  async fetchClaims<Claims = CiasClaims>(jwe: string, baseUrl: string, authority: IdentityProvider): Promise<Claims> {
    const xClientId = this.getXClientIdFor(authority);
    this.logger.debug(`fetching claims for ${authority}, ${xClientId}`);

    return this.http
      .get('jwt/claims', {
        headers: {
          Accept: 'application/json',
          'x-client-id': xClientId,
          Authorization: `Bearer ${jwe}`,
        },
        searchParams: {
          jwtIssuer: authority,
        },
        prefixUrl: baseUrl,
      })
      .json<Claims>()
      .then((claims) => {
        this.logger.log('got claims', claims);
        return claims;
      })
      .catch((e) => {
        this.logger.error('failed to fetch claims');
        throw e;
      });
  }

  async validateJwe<Claims = CiasClaims>(jwe: string, baseUrl: string, authority: IdentityProvider): Promise<Claims> {
    const xClientId = this.getXClientIdFor(authority);
    this.logger.debug(`validating JWE for ${authority}, ${xClientId} (${(jwe || '').substr(0, 5)}...)`);

    return this.http
      .get('jwt/validate', {
        headers: {
          'x-client-id': xClientId,
          Authorization: `Bearer ${jwe}`,
        },
        searchParams: {
          jwtIssuer: authority,
        },
        mode: 'cors',
        prefixUrl: baseUrl,
      })
      .json<Claims>()
      .then((claims) => {
        this.logger.log('jwe is valid', claims);
        return claims;
      })
      .catch((e) => {
        this.logger.error('Failed to validate jwe', e);
        throw e;
      });
  }

  protected async fetchCiasJwe(ott: string, baseUrl: string): Promise<string> {
    this.logger.debug('Fetching jwe from: ', baseUrl);

    try {
      if (!ott) {
        throw new Error('No OTT provided');
      }

      return await this.http.get(`jwe/${ott}`, { prefixUrl: baseUrl }).text();
    } catch (error) {
      this.logger.error('Unable to exchange OTT for JWE');
      throw error;
    }
  }

  protected async fetchBiasJwe(ott: string, baseUrl: string): Promise<string> {
    this.logger.debug('Fetching jwe from: ', baseUrl);

    const headers = {
      ott,
      accept: 'application/json',
    };

    return this.http
      .post('token', {
        headers,
        credentials: 'include',
        prefixUrl: baseUrl,
      })
      .json()
      .then((response) => (response as any).token)
      .catch((error) => {
        this.logger.error('An error happened trying to fetch JWE', error);
        throw error;
      });
  }

  private getXClientIdFor(authority: IdentityProvider): string {
    return (authority === IdentityProvider.CIAS ? ciasConfig.appName : biasConfig.tenantId) as string;
  }
}

export const httpClient = new HttpApi();
