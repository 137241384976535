import { AuthenticationState, IdentityProvider, Logger, TENANT, WKOService } from '@b2x/common';
import {
  connectionName as componentRegistryConnection,
  OwcComponentRegistryState as componentRegistryState,
} from '@owc/component-registry-connection';
import { ConnectionAemEnvironmentState, connectionName as aemEnvConnectionName } from '@owc/connection-aem-environment';
import { initializeStore, Subscription } from '@seamless/store';

import { getAuthenticationStorage } from '@/common/storage';

import { AUTHENTICATION_CONNECTION, CONDITION_TYPE, initialState, QUERY_PARAMS } from '../common/constants';
import { getQueryParameter } from '../common/helpers';
import { isLoggedIn } from '../common/selectors';
import { buildTime, version } from '../common/version';
import { AuthenticationConnection } from '../connections/auth-connection';
import { AuthenticationDispatchers, AuthenticationParams, Conditions, ConnectionBuilder } from '../types/auth';
import { BiasAuthClient } from './clients/bias';
import { CiasAuthClient } from './clients/cias';
import { Settings } from './settings';

const logger = new Logger('AUTH_BOOTSTRAP');

/**
 * Subscribes to the component registry and checks if any component requires login and environment is not AUTHOR
 */
const detectPageRequiresAuth = async () => {
  const store = initializeStore();
  let subscription: Subscription;

  return new Promise<boolean>((resolve) => {
    store.once(aemEnvConnectionName, (envState: ConnectionAemEnvironmentState) => {
      let result = false;

      if (envState.runMode === 'AUTHOR') {
        return resolve(result);
      }

      subscription = store.subscribe(componentRegistryConnection, (state: componentRegistryState) => {
        const componentKeys = Object.keys(state.components);
        logger.debug('Components registry counter:', componentKeys?.length);

        result = componentKeys.length
          ? componentKeys.some((key) => state.components[key]?.config?.userSessionRequired)
          : false;
      });

      // Timeout was the only way for us to fulfill all components registry are done
      // Maybe seamless could not iterate but get all component object at once.
      const timeoutSettled = setTimeout(() => {
        logger.debug('As private component:', result);

        clearTimeout(timeoutSettled);
        resolve(result);
      }, 50);
    });
  }).finally(() => {
    subscription.unsubscribe();
  });
};

/**
 * Get conditions define the condition state. This state is used in the client to perform
 * business decisions and also to dispatch actions to the store.
 */
const getConditions = async (settings: Settings): Promise<Conditions> => {
  logger.debug('Initialize condition check');

  let type = CONDITION_TYPE.ANONYMOUS;
  const isPrivatePage = settings.isOneWeb ? await detectPageRequiresAuth() : false;

  const token = getQueryParameter(QUERY_PARAMS.TOKEN) || getQueryParameter(QUERY_PARAMS.OTT);
  const owcAemJwe = WKOService.getAemJwe();
  const error = {
    // CIAS Error
    error: getQueryParameter(QUERY_PARAMS.ERROR),
    errorId: getQueryParameter(QUERY_PARAMS.ERROR_ID),
    state: getQueryParameter(QUERY_PARAMS.STATE),
    // BIAS Error
    error_code: getQueryParameter(QUERY_PARAMS.ERROR_CODE),
    error_description: getQueryParameter(QUERY_PARAMS.ERROR_DESCRIPTION),
    exception_id: getQueryParameter(QUERY_PARAMS.EXCEPTION_ID),
  };

  const hasError = Object.values(error).some((errorParam) => errorParam);

  const persistedState = getAuthenticationStorage();

  if (hasError) {
    type = CONDITION_TYPE.ERROR;
  } else if (persistedState && isLoggedIn(persistedState)) {
    type = CONDITION_TYPE.AUTHENTICATED;
  } else if (token) {
    type = CONDITION_TYPE.AUTHENTICATING;
  } else if (owcAemJwe) {
    type = CONDITION_TYPE.LEGACY;
  }

  const defaultState: AuthenticationState = {
    ...initialState,
    identityProvider: settings.getItem('identityProvider') as IdentityProvider,
  };

  const condition: Conditions = {
    token,
    owcAemJwe,
    error,
    isPrivatePage,
    state: persistedState || defaultState,
    hasError,
    type,
  };

  logger.debug(`Auth conditions type: ${type} as:`, condition);

  return condition;
};

/**
 * Everything as a started point and the initialize performs subscriptions, get condition state and expose
 * the public API to our clients.
 */
export const initializeAuthentication = async (options: AuthenticationParams): Promise<ConnectionBuilder> => {
  logger.debug('Build time and version', version, buildTime);
  // logger.debug('Initialize authentication with options: ', options);

  const store = initializeStore();
  const settings = new Settings(options);
  const conditions = await getConditions(settings);

  const authConnection = new AuthenticationConnection(conditions.state);

  authConnection.addClient(new CiasAuthClient(settings));
  authConnection.addClient(new BiasAuthClient(settings));

  logger.debug('initialState', authConnection.initialState);

  await store.addConnection(authConnection);

  const selectors = authConnection.getPublicSelectors();
  const dispatchers = await store.getConnectionDispatchers<AuthenticationDispatchers>(AUTHENTICATION_CONNECTION);

  store.once(AUTHENTICATION_CONNECTION, (state: AuthenticationState) => {
    logger.debug('Auth connection initialized as:', { state, persistedState: conditions.state });
    dispatchers.init(conditions);
  });

  return Object.seal({
    dispatchers,
    settings,
    selectors,
  });
};

/**
 * On AEM environment we auto initialize the authentication library
 */
export async function initializeUsingAEM(): Promise<void> {
  logger.warn('Initialization on AEM environment');

  const store = initializeStore();

  const aemConnection = await store.getConnectionDispatchers(aemEnvConnectionName, 500).catch(() => {
    logger.warn('No AEM connection available');
    return false;
  });

  if (aemConnection) {
    try {
      const { country, language, stage } = await new Promise((resolve) => {
        store.once(aemEnvConnectionName, (state: ConnectionAemEnvironmentState) => {
          logger.debug('AEM integration', state);
          resolve(state);
        });
      });

      const options: AuthenticationParams = {
        identityProvider: IdentityProvider.CIAS,
        country,
        language,
        stage: stage.toLowerCase(),
        tenantId: location.hostname.includes('localhost') ? TENANT.CIAS : TENANT.ONEWEB,
        logoutOptions: {
          targetUrl: WKOService.getPostLogoutUrl() || location.origin,
        },
      };

      initializeAuthentication(options);
    } catch (e) {
      logger.error('AEM connection rejected', e);
    }
  }
}
