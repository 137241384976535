import { initializeStore, SeamlessCoreStore, Subscription } from '@seamless/store';
import { B2X_ACTIVE_PROFILE_CONNECTION_NAME } from '../constants';
import { B2XActiveProfileState, MetaState } from '../state';
import { B2XActiveUserDispatchers } from '../dispatchers';
import { toIterator } from './iterable-utils';

export async function loadActiveProfileFromStore(): Promise<B2XActiveProfileState> {
  return new Promise((res, _rej) => {
    initializeStore().once(B2X_ACTIVE_PROFILE_CONNECTION_NAME, (state: B2XActiveProfileState) => {
      res(state);
    });
  });
}

export function subscribeToActiveProfile(callback: any): Subscription {
  return initializeStore().subscribe(B2X_ACTIVE_PROFILE_CONNECTION_NAME, async (state: B2XActiveProfileState) => {
    callback(state);
  });
}

export const activeProfiles = {
  [Symbol.asyncIterator]: activeProfilesIterator,
};

export function activeProfilesIterator() {
  return toIterator<B2XActiveProfileState>((c) => {
    const sub = initializeStore().subscribe(B2X_ACTIVE_PROFILE_CONNECTION_NAME, c);
    return () => sub.unsubscribe();
  });
}

export async function getActiveProfileDispatchers(store?: SeamlessCoreStore): Promise<B2XActiveUserDispatchers> {
  return await (store || initializeStore()).getConnectionDispatchers<B2XActiveUserDispatchers>(
    B2X_ACTIVE_PROFILE_CONNECTION_NAME,
  );
}

export async function loadReadyActiveProfileFromStore(wanted: MetaState = 'READY'): Promise<B2XActiveProfileState> {
  return new Promise<B2XActiveProfileState>((res) => {
    let done = false;
    let sub: Subscription | null = null;
    sub = subscribeToActiveProfile((state?: B2XActiveProfileState) => {
      if (state?.meta?.status === wanted && !done) {
        sub?.unsubscribe();
        done = true;
        res(state);
      }
    });
    // If already done (the value was already there), unsubscribe
    // NOTE: seamless behaves synchronously when the value is already present
    if (done) {
      sub.unsubscribe();
    }
  });
}

// Experimental version leveraging async iterable API
export async function loadReadyActiveProfileFromStoreExperimental(wanted: MetaState = 'READY') {
  for await (const state of activeProfiles) {
    if (state.meta?.status === wanted) {
      return state;
    }
  }
}
