import { AxiosRequestConfig } from 'axios';
import { Config } from '../model/config';

/**
 * The XMLHttpRequest.withCredentials property is a Boolean that indicates whether or not cross-site Access-Control requests
 * should be made using credentials such as cookies, authorization headers or TLS client certificates.
 * Setting withCredentials has no effect on same-site request
 */
export function getCorsCredentialsInterceptor(
  appConfig: Config,
): (config: AxiosRequestConfig<any>) => AxiosRequestConfig<any> {
  return (config: AxiosRequestConfig<any>) => {
    return {
      ...config,
      withCredentials: appConfig.corsCredentials,
    };
  };
}
