import { initializeStore } from '@seamless/store';
import {
  addConnectionAemEnvironmentConnection,
  ConnectionAemEnvironmentState,
  connectionName,
} from '@owc/connection-aem-environment';
import { countryMapping } from './countrymapping';

export async function getAemEnvironmentPromise(): Promise<ConnectionAemEnvironmentState> {
  return new Promise((resolve) => {
    const store = initializeStore();
    try {
      addConnectionAemEnvironmentConnection();
    } finally {
      store.once(connectionName, (state: ConnectionAemEnvironmentState) => {
        resolve(state);
      });
    }
  });
}

export const aemEnvironmentHandler = {
  getAemEnvironment(): Promise<ConnectionAemEnvironmentState> {
    return getAemEnvironmentPromise();
  },

  async getMarketAndLocale(): Promise<{
    market: string;
    locale: string;
    country: string;
    language: string;
  }> {
    const aemEnvironment = await getAemEnvironmentPromise();
    const country = aemEnvironment.country;
    const language = aemEnvironment.language;
    const mappedCountry = countryMapping.toCountry(country);
    return {
      market: mappedCountry,
      locale: `${language}-${country}`,
      country: mappedCountry,
      language,
    };
  },

  async getStage(): Promise<string> {
    const aemEnvironment = await getAemEnvironmentPromise();
    return aemEnvironment.stage;
  },

  async getRunMode(): Promise<string> {
    const aemEnvironment = await getAemEnvironmentPromise();
    return aemEnvironment.runMode;
  },
};
