import { AxiosRequestConfig } from 'axios';

/**
 * x-applicationname gets tracked by our logging
 * Furthermore we send this header to the MIC-Backends so they can do some logging as well.
 */
export function getApplicationNameInterceptor(
  applicationName: string,
): (config: AxiosRequestConfig<any>) => AxiosRequestConfig<any> {
  return (config: AxiosRequestConfig<any>) => {
    const headers = {
      ...config.headers,
      'x-applicationname': applicationName,
    };

    return {
      ...config,
      headers,
    };
  };
}
