import store from '@/store';
import CMWState from '@/store/cmw-state';
import { B2XContext } from '@commons-fe/mmcs-models';
import { CMStatus, cmStoreActions, ValidationStatus } from '@dh-io-mmcs/consents-management-store/vuex';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { logger } from '@dh-io-mhb2b/fe-services';

@Component({
  name: 'footer-component',
})
export default class Footer extends Vue {
  @Prop({ default: false, required: false, type: Boolean }) private readonly hideCancel!: boolean;
  @Prop({ default: false, required: false, type: Boolean }) private readonly agreementIsOptional!: boolean;

  get isContentReady(): boolean {
    return !!CMWState.cmStore.consentCount;
  }
  get isSaving(): boolean {
    return CMWState.cmStore.status === CMStatus.SAVING;
  }
  get isContentStatusValid(): boolean {
    return CMWState.cmStore.validationResult.status !== ValidationStatus.INVALID;
  }

  async onSave(): Promise<void> {
    if (
      !this.agreementIsOptional &&
      CMWState.cmStore.validationResult.status === ValidationStatus.INVALID &&
      CMWState.cmStore?.initConfig?.b2xContext === B2XContext.B2B
    ) {
      logger.error('footer onSave check failed', CMWState.cmStore.validationResult.status);
      return;
    }
    if (!this.isSaving) {
      this.$emit('save');
      await store.dispatch(cmStoreActions.SET_CM_STATUS, { status: CMStatus.SAVING });
      logger.log('Saving consent.');
    }
  }

  onCancel(): void {
    this.$emit('cancel');
  }
}
